export const loadState = () => {
    try{
        const serialisedState = sessionStorage.getItem('state');
        if(serialisedState === null){
            return {};    
        }
        return JSON.parse(serialisedState);
    } catch(error){
        console.log(error);
        return {};
    }
};

export const saveState = (state) => {
    try{
        const serialisedState = JSON.stringify(state);
        sessionStorage.setItem('state', serialisedState)
    } catch(error){
        console.log(error);
    }
};