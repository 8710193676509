import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Icon, Grid } from 'semantic-ui-react';
import { issuesService, organisationService } from '../../services'
import { SidebarComponent, Navbar } from '../../_components';
import {IssueLocation, IssueDetails, IssueTypeSelector} from '../../_components/issues'
import { Comments,  } from '../../_components/common';
import CommonHelper from '../../_helpers/CommonHelper';
import {permissionConstants, featureConstants, appConstants} from '../../_constants'

class IssuePage extends Component {
    constructor(props) {
        super(props);

        this.createNewIssue = this.createNewIssue.bind(this);
        this.updateIssue = this.updateIssue.bind(this);
        this.addComment = this.addComment.bind(this);
        this.setLocationUsingName = this.setLocationUsingName.bind(this);
        this.setIssueProperty = this.setIssueProperty.bind(this);
        this.issueTypeSelected = this.issueTypeSelected.bind(this);
        this.clearIssueLocationProperties = this.clearIssueLocationProperties.bind(this);
        this.addIssueParent = this.addIssueParent.bind(this);
        this.addIssueAuxField = this.addIssueAuxField.bind(this);
        this.parentOrgSelected = this.parentOrgSelected.bind(this);
        this.childOrgSelected = this.childOrgSelected.bind(this);
        this.locationSelected = this.locationSelected.bind(this);
        this.entitySelected = this.entitySelected.bind(this);
        this.getIssue = this.getIssue.bind(this);
        this.canSaveCheck = this.canSaveCheck.bind(this)

        this.locale = CommonHelper.getLocate(this.props.user);
        this.dateformat = CommonHelper.getLocalDateFormat(this.props.user, true);

        this.state = {
            currentIssue: {
                parents: {},
                auxFields: {}
            },
            editMode: false,
            hasEditPermission: false,
            createIssueMode: false,
            currentLocation: {}
        };

        this.canSaveCheck();
    }

    componentDidMount() {
        if(this.props.hasIssueFeature === false){
            this.props.dispatch(push('/forbidden'));
            return;
        }

        if(this.props.params.id){
            //Edit Mode
            this.getIssue();
        } else {
            //Create Mode
            this.setState({
                createIssueMode: true,
                editMode: true,
                createModalIsOpen: true
            });
        }
    }

    /*CRUD functions*/
    getIssue() {
        const self = this;
        issuesService.getIssueById(this.props.params.id).then(function(result) {
            const parsed = JSON.parse(result.data);
            self.setState({ currentIssue: parsed });
        });
    }

    updateIssue() {
        const self = this;
        let urlQueryString = self.props.location.query.jobid == undefined ? '' : '?jobid='+self.props.location.query.jobid;
        issuesService.updateIssue(this.state.currentIssue).then(function(){
            self.props.dispatch(push('/issueList'+urlQueryString));
        });
    }

    createNewIssue() {
        issuesService.createIssue(this.state.currentIssue)
            .then(() => {
                this.props.dispatch(push('/issueList'));
            });
    }

    addComment(comment) {
        const currentIssue = this.state.currentIssue;
        if (!currentIssue.comments){
            currentIssue.comments = [];
        }

        const newComment = {
            comment: comment,
            commentDate: moment().utc().format(),
            userId: this.props.user.profile.sub,
            userName: this.props.user.profile.name
        };

        currentIssue.comments.push(newComment);

        if(!this.state.createIssueMode){
            const self = this;
            issuesService.addComment(currentIssue.id, newComment).then(() => {
                self.setState({currentIssue, newCommentText: ''});
            });
        }else{
            this.setState({currentIssue, newCommentText: ''});
        }
    }

    /*Events and helpers*/
    issueTypeSelected(issueType){
        const issue = this.state.currentIssue;
        issue.issueTypeId = issueType.id;
        issue.description = issueType.description;
        issue.priority = { priority: issueType.priority, priorityOrder: issueType.priorityOrder };
        issue.issueType = { issueTypeId: issueType.id, issueTypeName: issueType.name };

        this.setState({ currentIssue: issue, createModalIsOpen: false });   
    }

    setIssueProperty(value, key) {
        const issue = this.state.currentIssue;
        issue[key] = value;
        this.setState({ currentIssue: issue });
    }

    cancelEdit(){
        if(this.state.createIssueMode){
            this.props.dispatch(push('/issueList'));
        }
        else{
            this.setState({ editMode: false }, () => this.getIssue());
        }
    }

    setLocationUsingName(assetlist, loclist, assetId) {
        var selectedAsset = assetlist.find(function(obj) {
            return obj.id === assetId;
        });
        var corrLoc = loclist.find(function(obj) {
            return obj.text === selectedAsset.location;
        });
        if (corrLoc) {
            this.setState({
                location: corrLoc.value,
                locationName: corrLoc.text,
                locationOjbect: { _id: corrLoc.value, tag: corrLoc.text }
            });
        }
    }

    parentOrgSelected(name, id){
        this.clearIssueLocationProperties(['entity', 'childOrg', 'childOrgName', 'location' , 'entityName', 'entityNumber', 'entityTypeName']);
        this.addIssueParent(name, id, 'parentOrg');
        this.addIssueAuxField(name, 'parentOrgName');

        organisationService.getAllParents(id).then((response) => {
            if (!response.data) return;
            const { currentIssue } = this.state;
            currentIssue.ancestors = response.data;
            this.setState({ currentIssue });
        });
    }

    childOrgSelected(name, id){
        this.clearIssueLocationProperties(['entity', 'location', 'entityName', 'entityNumber', 'entityTypeName']);
        this.addIssueParent(name, id, 'childOrg');
        this.addIssueAuxField(name, 'childOrgName');
    }

    locationSelected(name, id){
        this.clearIssueLocationProperties(['entity', 'entityName', 'entityNumber', 'entityTypeName']);
        this.addIssueParent(name, id, 'location');
        this.addIssueAuxField(name, 'location');

        const currentLocation = { tag: name, _id: id };
        this.setState({ currentLocation });
    }

    entitySelected(asset){
        if(!asset) return;

        this.locationSelected(asset.location, asset.locationId);
        this.addIssueParent(asset.name, asset.id, 'entity');

        this.addIssueAuxField(asset.name, 'entityName');
        this.addIssueAuxField(asset.assetNumber, 'entityNumber');
        this.addIssueAuxField(asset.assetTypeName, 'entityTypeName');
    }

    addIssueParent(name, id, type) {
        const issue = this.state.currentIssue;
        const parents = issue.parents;

        parents[type] = { type, name, id };
        issue.parents = parents;
        this.setState({ currentIssue: issue });
    }

    addIssueAuxField(name, type){
        const issue = this.state.currentIssue;
        const auxFields = issue.auxFields;
        auxFields[type] = name;

        issue.auxFields = auxFields;
        this.setState({ currentIssue: issue });
    }

    clearIssueLocationProperties(keys){
        const issue = this.state.currentIssue;
        const parents = issue.parents;
        const auxFields = issue.auxFields;

        keys.forEach(function(k){
            delete parents[k];
            delete auxFields[k];
        });

        issue.parents = parents;
        issue.auxFields = auxFields;
        this.setState({ currentIssue: issue });
    }

    canSaveCheck() {
        const description = _.get(this.state.currentIssue, 'description');
        const priority = _.get(this.state.currentIssue, 'priority');
        const issueTypeId = _.get(this.state.currentIssue, 'issueTypeId');
        const editParents = _.get(this.state.currentIssue, 'systemHeader_parents');
        const createParents = _.get(this.state.currentIssue, 'parents');
        const editParentOrg = _.find(editParents, (p) => p.type === 'parentOrg')
        const createParentOrg = createParents ? createParents.parentOrg : null;
        const hasParentOrg = (editParentOrg || createParentOrg);
        this.canSave = (description && priority && hasParentOrg && issueTypeId);       
    }

    render() {
        return (
            <div>
                <Navbar handleClick={(event) => console.log(event.target)}/>
                <SidebarComponent content={this.renderPage()}/>
            </div>
        );
    }

    renderPage() {
        this.canSaveCheck();
        let updatedDate = (
            <div>
                {!this.state.createIssueMode ?
                    <small id="issue-last-update">
                        Last update&nbsp;
                        {CommonHelper.localisedDate(this.props.user, _.get(this.state.currentIssue, 'systemHeader_updateDetails.date'))}
                    </small>
                    : null
                }
            </div>
        );

        let editActions = (
            <div className="actions">
                {this.props.hasEditPermission === true?
                    <span id="issue-edit-buttons">
                        {!this.state.editMode?
                            <button
                                onClick={() => this.setState({editMode:true})}
                                className="btn btn-primary btn-danger">
                                Edit <Icon name="edit" />
                            </button>
                            :
                            <div>
                                <button
                                    onClick={() => this.cancelEdit()}
                                    className="btn btn-primary btn-danger">
                                    Cancel <Icon name="cancel" />
                                </button>
                                <button
                                    disabled={!this.canSave}
                                    onClick={() => {
                                        if(this.state.createIssueMode){
                                            this.createNewIssue();
                                        }else{
                                            this.updateIssue();
                                        }
                                    }}
                                    className="btn btn-outline btn-primary">
                                    Save <Icon name="save" />
                                </button>
                            </div>
                        }
                    </span>
                    : null
                }
            </div>
        );

        const parents = _.get(this.state.currentIssue, 'systemHeader_parents', []);
        const locParent = _.find(parents, p => p.type === 'parentOrg');
        const locChild = _.find(parents, p => p.type === 'childOrg');
        const locLoc = _.find(parents, p => p.type === 'location');
        const locAss = _.find(parents, p => p.type === 'entity');

        return (
            <div>
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <h2 className="form-heading">
                                <em>{_.get(this.state.currentIssue,'issueType.issueTypeName')} </em>
                                {!this.state.createIssueMode ? <small> Ref: {_.get(this.state.currentIssue, 'issueRef')}</small> : null }
                            </h2>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div className="actions">
                                {updatedDate}
                                {editActions}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="ibox-content">
                        <Grid.Column width={16} className="issue-details-header">
                            <h1>Issue Details</h1>
                        </Grid.Column>
                        <Grid.Column width={3} className="issue-cell">
                            <IssueLocation
                                itemsInSelect={appConstants.ITEMS_IN_SELECT}
                                parents={this.state.currentIssue.parents}
                                disableAll={!this.state.createIssueMode}
                                editMode={this.state.editMode && this.state.createIssueMode}
                                setLocationUsingName={this.setLocationUsingName}
                                rootstate={this.state}
                                currentLocation={this.state.currentLocation}

                                parentOrgSelected = {this.parentOrgSelected}
                                childOrgSelected = {this.childOrgSelected}
                                locationSelected = {this.locationSelected}
                                entitySelected = {this.entitySelected}

                                locationPlaceholderParent={_.get(locParent, 'name')}
                                locationPlaceholderChild={_.get(locChild, 'name')}
                                locationPlaceholderAsset={_.get(locAss, 'name', ' ')}
                                locationPlaceholderLocation={_.get(locLoc, 'name')}
                            />
                        </Grid.Column>
                        <Grid.Column width={13} className="issue-details-devider issue-cell">
                            <IssueDetails
                                setIssueProperty={this.setIssueProperty}
                                editMode={this.state.editMode }
                                issuePriority={_.get(this.state.currentIssue, 'priority.priority')}
                                issueDescription={_.get(this.state.currentIssue, 'description')}
                                issueStatus={_.get(this.state.currentIssue, 'state')}
                                issueValue={_.get(this.state.currentissue, 'auxFields.recordedValue', null)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column className="ibox-content">
                            <Comments
                                enabled={this.props.hasViewPermission}
                                comments={this.state.currentIssue.comments}
                                addComment={this.addComment}
                                dateFormat={this.dateformat}
                                title="Issue Comments"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {this.state.createIssueMode ?
                    <IssueTypeSelector
                        modalIsOpen = {this.state.createModalIsOpen}
                        issueTypeSelected = {this.issueTypeSelected}
                        selectionCanceled = {() => this.props.dispatch(push('/issueList'))}
                    />
                    :null
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { component, oidc } = state;
    const { sidebarVisible } = component;
    const { user }  = oidc;
    const viewPermission = CommonHelper.arrayContainsOneOrMoreStrings(state.auth.permissions, permissionConstants.GOD_MODE, permissionConstants.ISSUE.VIEW);
    const createPermission = CommonHelper.arrayContainsOneOrMoreStrings(state.auth.permissions, permissionConstants.GOD_MODE, permissionConstants.ISSUE.CREATE);
    const issueFeature = CommonHelper.arrayContainsOneOrMoreStrings(state.auth.features, featureConstants.ISSUES);

    return {
        sidebarVisible,
        user,
        hasEditPermission: createPermission,
        hasViewPermission: viewPermission,
        hasIssueFeature: issueFeature
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}
const connectedIssuePage = connect(mapStateToProps, mapDispatchToProps)(IssuePage);
export { connectedIssuePage as IssuePage };