import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import {DocumentList, SiteVisitResults} from './'
import CommonHelper from '../_helpers/CommonHelper';
import {permissionConstants} from '../_constants'

class JobCard extends React.Component {
    constructor(props){
        super(props);
        this.getDayWithOrdinal = this.getDayWithOrdinal.bind(this);
        this.showTemplateModal = this.showTemplateModal.bind(this);
        this.getDocumentComponent = this.getDocumentComponent.bind(this);

        this.state = {
            templateModalOpen: []
        };
    }

    getDayWithOrdinal(n) {
        var s=["th","st","nd","rd"],
            v=n%100;
        return n+(s[(v-20)%10]||s[v]||s[0]);
    }

    showTemplateModal(e, jobid){
        e.preventDefault();
        
        let tmo = this.state.templateModalOpen;
        let shown = tmo[jobid];

        if(!shown) 
            shown = false;

        tmo[jobid] = !shown

        this.setState({templateModalOpen: tmo});
    }
    
    jobHasDocuments(job){
        return job.isSiteVisit || job.hasDocumentForClient;
    }

    getDocumentComponent(job){
        if(this.jobHasDocuments(job)){
            return (
                <DocumentList
                    show={this.state.templateModalOpen[job.id]}
                    jobId= {job.id}
                    jobTypeId={job.jobTypeId}
                    closeModal={(event) =>this.showTemplateModal(event, job.id)}
                    isSiteVisit={job.isSiteVisit}
                />);
        }

        return null;
    }

    render (){
        return(
            <div>
                {this.props.jobs ?
                    this.props.jobs.map(
                        (job, i) => 
                            <div key={i} className={`ui card boxed ${job.pattern} ${this.jobHasDocuments(job) ? 'pointer':''}`}>
                                <div className="content" onClick={(event) =>this.showTemplateModal(event, job.id)}>
                                    <div className="ui grid two column">
                                        <div className="column">
                                            <div className="ui label left floated">{this.getDayWithOrdinal(new Date(job.completedDate).getDate())}</div>
                                        </div>
                                        {job.issueCount !== 0 && this.props.hasIssueViewPermission ?
                                            <div className="column" onClick={() => this.props.dispatch(push('/issueList?jobid='+job.id))}>
                                                <div className="ui label right floated red circular" data-position="top center" data-tooltip="Number of issues reported">{job.issueCount}</div>
                                            </div>:
                                            null}
                                    </div>
                                    
                                    <div className="description" >
                                        <p>{job.name}</p>
                                        <p>{job.reference}</p>
                                    </div>
        
                                    {job.isRecurrence ?
                                        <div className="extra content" data-position="top left" data-tooltip={`${job.pattern} job`}>
                                            <div className={`ui left corner mini label ${job.pattern}`}>{job.pattern.charAt(0)}</div>
                                        </div> :
                                        <div className="extra content" data-position="top left" data-tooltip="One-off job">
                                            <div className={`ui left corner mini label`}></div>
                                        </div>
                                    } 
                                </div>
                                {this.getDocumentComponent(job)}
                            </div>)
                    :<div></div>}
            </div>
        );
    }
    
}

JobCard.propTypes = {
    jobs: PropTypes.array
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

function mapStateToProps(state) {
    const viewPermission = CommonHelper.arrayContainsOneOrMoreStrings(state.auth.permissions, permissionConstants.GOD_MODE, permissionConstants.ISSUE.VIEW);

    return {
        hasIssueViewPermission: viewPermission
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(JobCard);