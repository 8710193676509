import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'react-html-id';
import { Icon, Dropdown } from 'semantic-ui-react';

const options = [];

export default class TagLookup extends React.Component {
    constructor(props) {
        super(props);

        this.state = { options };
        this.itemInList = props.constItemsInSelect;
        this.lock = false;

        this.handleChange = (e, data, newtag = false) => {
            const me = this;
            if (this.props.multiple) {
                if (!newtag) {
                    const responseArray = [];
                    this.setState({ value: data.value });
                    data.value.forEach(function(val) {
                        var optval = me.state.options.find(function(op) {
                            return op.value === val;
                        });
                        if (optval) responseArray.push(optval);
                        me.props.tagchangedcallback(responseArray);
                    });
                }
            } else {
                if (this.state.options.filter(tag => tag.tag === data.text).length > 0) {
                    this.setState({ value: data.value });

                    let locobj = data.options.find(function(obj) {
                        return obj.value === data.value;
                    });
                    this.props.tagchangedcallback({
                        _id: locobj.value,
                        tag: locobj.text,
                        order: locobj.key
                    });
                }
            }
        };

        this.handleClear = () => {
            if (this.props.multiple) {
                this.setState({ value: [] });
                this.props.tagchangedcallback([]);
            } else {
                this.setState({ value: null });
                this.props.tagchangedcallback(null);
            }
        };

        this.handleOnOpen = () => {
            this.searchForTags(props.tagsService, '');
        };

        this.handleAddition = (e, { value }) => {
            var me = this;
            props.tagsService.createTag(value, this.props.parentid).then(
                function(result) {
                    if (me.props.multiple) {
                        const tag = result.data;
                        me.saveTag(tag._id, tag.tag, tag.order, false);
                        // me.props.tagchangedcallback();
                    } else {
                        let tag = result.data;
                        me.saveTag(tag._id, tag.tag, tag.order, false);
                        me.props.tagchangedcallback(tag);
                    }

                    //On success
                },
                function() {
                    //On error
                }
            );
        };

        this.handleSearchChange = (e, { searchQuery }) => {
            this.searchForTags(props.tagsService, searchQuery);
        };

        this.showIcon = this.props.showClearIcon ? {} : { display: 'none' };
        uniqueId.enableUniqueIds(this);
    }

    searchForTags(tagsService, searchTerm) {
        var me = this;
        var page = null;
        var take = null;

        if (searchTerm === undefined || searchTerm === '') {
            page = 0;
            take = me.itemInList;
        }
        if (!this.lock) {
            this.lock = true;
            tagsService
                .getTagsPage(this.props.parentid, page, take, searchTerm)
                .then(function(result) {
                    me.lock = false;
                    me.state.options = [];
                    me.setState({ options });
                    result.data.forEach(function(element) {
                        me.addTagToTheOptionsList(element, false);
                    });
                });
        }
    }

    addTagToTheOptionsList(newTag, saveAsSelectedValue) {
        if (!newTag._id && newTag.tag) {
            var me = this;
            if (
                !this.lock &&
                this.state.options.filter(tag => tag.tag === newTag.tag).length === 0
            ) {
                this.lock = true;
                this.props.tagsService
                    .getTagsPage(this.props.parentid, null, null, newTag.tag)
                    .then(function(tags) {
                        me.lock = false;
                        if (tags.length > 0) {
                            let pulledTag = tags[0];
                            me.saveTag(
                                pulledTag._id,
                                pulledTag.tag,
                                pulledTag.order,
                                saveAsSelectedValue
                            );
                        }
                    });
            }
        } else if (this.state.options.filter(tag => tag._id === newTag._id).length === 0) {
            this.saveTag(newTag._id, newTag.tag, newTag.order, saveAsSelectedValue);
        }
    }

    saveTag(tagId, tagName, tagOrder, saveAsSelectedValue) {
        this.setState({
            options: [...this.state.options, { key: tagOrder, text: tagName, value: tagId }]
        });
        if (saveAsSelectedValue) {
            this.setState({ value: tagId });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.setOptions) {
            this.setState({
                options: this.props.options,
                value: this.props.currentvalue
            });
        } else {
            let curValue = nextProps.currentvalue;
            if (curValue == null) {
                this.setState({ value: null });

                if(!nextProps.parentid){
                    this.setState({options: [], value: null});
                }

            } else if (this.state.multiple) {
                this.setState({ value: curValue });
            } else {
                if (this.state.options.filter(option => option.value === curValue._id).length === 0) {
                    this.addTagToTheOptionsList(curValue, true);
                }
            }
        }

        
    }

    componentDidMount() {
        this.componentWillReceiveProps(this.props);
    }

    render() {
        const { value } = this.state;
        let labelClass;
        this.props.required
            ? (labelClass = 'control-label required')
            : (labelClass = 'control-label');
        return (
            <div id={this.nextUniqueId() + '-container'} className="tag-container">
                {this.props.caption && <label className={labelClass}>{this.props.caption}</label>}
                <Icon
                    link
                    name="close"
                    onClick={this.handleClear}
                    style={this.showIcon}
                    className="tag-icon"
                />
                <Dropdown
                    placeholder={this.props.listPlaceholder}
                    multiple={this.props.multiple}
                    fluid
                    search
                    selection
                    allowAdditions
                    additionLabel={<i style={{ color: 'White' }}>Create new: </i>}
                    selectOnBlur={false}
                    options={this.state.options}
                    value={value}
                    onAddItem={this.handleAddition}
                    onChange={this.handleChange}
                    onSearchChange={this.handleSearchChange}
                    onOpen={this.handleOnOpen}
                    openOnFocus={false}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}

// Set the display name so ngReact can find your component
TagLookup.displayName = 'TagLookup';

TagLookup.propTypes = {
    parentid: PropTypes.string,
    currentvalue: PropTypes.object,
    tagchangedcallback: PropTypes.func,
    disabled: PropTypes.bool,
    tagsService: PropTypes.any,
    constItemsInSelect: PropTypes.number,
    listPlaceholder: PropTypes.string,
    showClearIcon: PropTypes.bool,
    required: PropTypes.bool,
    caption: PropTypes.string,
    multiple: PropTypes.bool,
    setOptions: PropTypes.bool,
    options: PropTypes.array
};

TagLookup.defaultProps = {
    listPlaceholder: 'Choose Item',
    constItemsInSelect: 10,
    showClearIcon: false,
    title: ''
};
