import React from "react";
import { connect } from "react-redux";

import { authConstants } from '../../_constants/auth.constants';
import { HoldingPage, SiteList} from '../index';
import { setTenantQuery } from '../../_actions';

function HomePage(props) {
    const { user, location, dispatch } = props;
    var expired = !user || user.expired;

    if(expired){
        var tenant = location.query.tenant;
        if (!tenant)
            tenant = localStorage[authConstants.LOCAL_TENANT_KEY];

        if (tenant)
        {
            localStorage[authConstants.LOCAL_TENANT_KEY] = tenant
            dispatch(setTenantQuery(tenant));
        }
    }

    if (!expired) return <SiteList />;

    return <HoldingPage />
}

function mapStateToProps(state) {
    return {
        user: state.oidc.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

const connectedHomePage = connect(mapStateToProps, mapDispatchToProps)(HomePage);
export { connectedHomePage as HomePage };
