import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, TextArea } from 'semantic-ui-react';

export default class ExtendedTextInput extends Component {
    render() {
        let labelClass;
        this.props.required
            ? (labelClass = 'control-label required')
            : (labelClass = 'control-label');
        return (
            <div className="extendedTextInput">
                <label className={labelClass}>{this.props.title}</label>
                <Form>
                    <TextArea
                        className="form-control"
                        placeholder={this.props.placeholder}
                        onChange={this.props.onChange}
                        value={this.props.value}
                        disabled={this.props.disabled}
                    />
                </Form>
            </div>
        );
    }
}

ExtendedTextInput.propTypes = {
    required: PropTypes.bool,
    title: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string
};
