import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SidebarComponent, Navbar } from '../../_components';
import { DocumentList } from '../../_components'
import { organisationService, organisationDocumentsService } from '../../services'
import { Tab, Grid, Button, Icon, Header } from 'semantic-ui-react'
import { SemTable } from '../../_components/common';
import CommonHelper from '../../_helpers/CommonHelper';

const defaultColumnIndexSort = 2;
class DocumentLibrary extends Component {
    constructor(props){
        super(props);

        this.renderPage = this.renderPage.bind(this);
        this.getOrganisationDetails = this.getOrganisationDetails.bind(this);
        this.populateOrganisationHierarchy = this.populateOrganisationHierarchy.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.populateTabPanes = this.populateTabPanes.bind(this);
        this.populateTabPanes = this.populateTabPanes.bind(this);
        this.getTabContents = this.getTabContents.bind(this);
        this.getDistinctTagsUsedByDocuments = this.getDistinctTagsUsedByDocuments.bind(this);
        this.groupDocumentsByTag = this.groupDocumentsByTag.bind(this);
        this.getTagTable = this.getTagTable.bind(this);
        this.downloadDocument = this.downloadDocument.bind(this);
        this.viewDocument = this.viewDocument.bind(this);
        this.isPdfDocument = this.isPdfDocument.bind(this);
        this.closeViewDocumentModal = this.closeViewDocumentModal.bind(this);
        this.sortColumn = this.sortColumn.bind(this);

        this.state = {
            isLoading: false,
            initialOrganisationDetails: { name: 'default' },
            initialOrganisationId: this.props.params.organisationId,
            initialOrganisationHasLoaded: false,
            currentlySelectedOrganisationDetails: { name: 'default' },
            tenantId: null,
            tenantName: null,
            siteId: null,
            siteName: null,
            clientId: null,
            clientName: null,
            currentlySelectedOrganisationDocuments: [],
            tabPanes:[],
            selectedTabIndex: 0,
            showDocumentViewModal: false,
            currentPopupModalDocument: { id: null, fileName: null },
            tableColumns: ["Document","Uploaded by","Added on","Controls"],
            tablesAndRows:{},
            sorting:{}
        }
    }

    componentWillMount() {
        this.getOrganisationDetails(this.state.initialOrganisationId)
            .then(() => {
                this.populateTabPanes();
            });
    }

    getOrganisationDetails(organisationId) {
        var promise = organisationService.getOrganisationDetails(organisationId, true)
            .then((result) => {
                if (this.state.initialOrganisationHasLoaded) {
                    this.setState((prevState, props) => ({
                        currentlySelectedOrganisationDetails: result.data,
                        currentlySelectedOrganisationDocuments: result.data.documents,
                    }));
                } else {
                    this.setState((prevState, props) => ({
                        initialOrganisationDetails: result.data,
                        currentlySelectedOrganisationDetails: result.data,
                        currentlySelectedOrganisationDocuments: result.data.documents,
                        initialOrganisationHasLoaded: true
                    }), () => {
                        this.populateOrganisationHierarchy();
                    });
                }
            });
        return promise;
    }

    populateOrganisationHierarchy() {
        var initialOrganisationDetails = this.state.initialOrganisationDetails;
        switch(this.state.initialOrganisationDetails.depth) {
            case 1:
                // tenant
                this.populateOrganisationInfo(initialOrganisationDetails.id,
                    initialOrganisationDetails.name);
                break;
            case 2:
                // client
                this.populateOrganisationInfo(initialOrganisationDetails.tenantOrganisationId,
                    initialOrganisationDetails.tenantOrganisationName,
                    initialOrganisationDetails.id,
                    initialOrganisationDetails.name);
                break;
            case 3:
                // site
                this.populateOrganisationInfo(initialOrganisationDetails.tenantOrganisationId,
                    initialOrganisationDetails.tenantOrganisationName,
                    initialOrganisationDetails.parentId,
                    initialOrganisationDetails.parentName,
                    initialOrganisationDetails.id,
                    initialOrganisationDetails.name);
                break;
            default:
                // unknown
        }
    }

    populateOrganisationInfo(tenantId, tenantName, clientId, clientName, siteId, siteName) {
        this.setState((prevState, props) => ({
            tenantId: tenantId || null,
            tenantName: tenantName || null,
            clientId: clientId || null,
            clientName: clientName || null,
            siteId: siteId || null,
            siteName: siteName || null,
        }));
    }

    handleTabChange(e, data) {
        if (data) {
            var selectedTab = data.panes[data.activeIndex];
            this.setState({selectedTabIndex: data.activeIndex});
            if (selectedTab.organisationId != null) {
                this.getOrganisationDetails(selectedTab.organisationId)
                    .then(() => {
                        this.populateTabPanes();
                    });
            }
        }
    }

    /**
     * Show/hide the relevant tab panes. 
     * E.g if we have a tenantId as the initial organizationId then we will only show the tenant tab.
     * If we have a clientId as the initial organizationId then we show the client tab and tenant tab. 
     * The hierarchy goes as follows:
     * tenant -> client -> site
     */
    populateTabPanes() {
        let tabPanesArray = [];
        if (this.state.tenantId) {
            tabPanesArray.push(this.getTabJsxObject(this.state.tenantId, this.state.tenantName));
        }

        if (this.state.clientId) {
            tabPanesArray.push(this.getTabJsxObject(this.state.clientId, this.state.clientName));
        }

        if (this.state.siteId) {
            tabPanesArray.push(this.getTabJsxObject(this.state.siteId, this.state.siteName))
        }

        this.setState((prevState, props) => ({
            tabPanes: tabPanesArray,
            selectedTabIndex: this.state.currentlySelectedOrganisationDetails.depth - 1
        }));
    }

    getTabJsxObject(organisationId, organisationName) {
        let tabContents;
        if (this.state.currentlySelectedOrganisationDetails.id === organisationId) {
            tabContents = this.getTabContents(organisationId);
        }
        var tabJsxObject = {
            menuItem: organisationName,
            organisationId: organisationId,
            render: () => <Tab.Pane attached={false}>{tabContents}</Tab.Pane>
        }
        return tabJsxObject;
    }

    getTabContents() {
        // Get distinct tags used by the documents.
        let tagsAndDocuments = {};
        let distinctTagsUsed = this.getDistinctTagsUsedByDocuments();
        distinctTagsUsed.forEach(function (currentTag) {
            tagsAndDocuments[currentTag.name] = [];
        })
        
        // Manually add an Uncategorised category for documents which have no category.
        tagsAndDocuments["Uncategorised"] = [];

        // Sort the documents by the tag they use.
        tagsAndDocuments = this.groupDocumentsByTag(tagsAndDocuments);

        // Get the tables and heading for each category.
        let categoryTables = this.getTagTables(tagsAndDocuments);

        if(categoryTables.length === 0) {
            categoryTables = (<p>No documents found.</p>);
        }
        return categoryTables;
    }

    /**
     * Get all distinct tags in use by currently selected org documents, order alphabetically.
     */
    getDistinctTagsUsedByDocuments() {
        let allTagsUsed = [];
        this.state.currentlySelectedOrganisationDetails.documents.forEach(function (currentDocument) {
            currentDocument.organisationDocumentTags.forEach(function (currentTag) {
                allTagsUsed.push(currentTag);
            })
        });

        const distinctTagsUsed = [...new Map(allTagsUsed.map(item =>
            [item['id'], item])).values()];

        return distinctTagsUsed;
    }

    /**
     * For the currently selected organisation sort the documents by category like so:
     * tagsAndDocuments start: {"Category1": [],"Category2": []}
     * tagsAndDocuments finish: {"Category1": [document1, document2, document3],"Category2": [document2, document4]}} 
     */
    groupDocumentsByTag(tagsAndDocuments) {
        // Sort the documents with categories.
        for (const [currentTagName, documentsWithCurrentTag] of Object.entries(tagsAndDocuments)) { 
            this.state.currentlySelectedOrganisationDetails.documents.forEach(function (currentDocument) {
                if (currentDocument.organisationDocumentTags && currentDocument.organisationDocumentTags.length > 0) {
                    // This document has one or more categories.
                    currentDocument.organisationDocumentTags.forEach(function (currentTag) {
                        if (currentTagName === currentTag.name) {
                            tagsAndDocuments[currentTagName].push(currentDocument);
                        }
                    })
                }
            });
        }

        // Deal with documents without tags and place them into the hardcoded "Uncategorised" category.
        this.state.currentlySelectedOrganisationDetails.documents.forEach(function (currentDocument) {
            if (currentDocument.organisationDocumentTags === undefined || currentDocument.organisationDocumentTags.length === 0) {
                tagsAndDocuments["Uncategorised"].push(currentDocument);
            }
        });

        // If there were no uncategorised documents delete the property so it won't display an empty "Uncategorised" table.
        if (tagsAndDocuments["Uncategorised"].length === 0) {
            delete tagsAndDocuments["Uncategorised"];
        }

        return tagsAndDocuments;
    }

    getTagTables(tagsAndDocuments) {
        let tagTables = [];
        let key = 0;
        for (const [currentTag, documentsWithCurrentTag] of Object.entries(tagsAndDocuments)) {
            let rows = this.getTagTableRows(documentsWithCurrentTag);
            if (this.state.sorting && this.state.sorting[currentTag]) {
                let headingIndex = this.state.sorting[currentTag][0]
                if (this.state.sorting[currentTag][1]) {
                    rows.sort(this.ascending(headingIndex))
                }
                else {
                    rows.sort(this.descending(headingIndex))
                } 
            }
            else {
                rows.sort(this.descending(defaultColumnIndexSort))
            }

            let currentTablesAndRows = this.state.tablesAndRows;
            currentTablesAndRows[currentTag] = rows;
            this.setState({
                tablesAndRows:currentTablesAndRows
            })

            tagTables.push(this.getTagTable(currentTag, key));
            key++;
        }
        return tagTables;
    }

    descending(index) {
        return (a,b) => (a[index] > b[index]) ? 1 : ((b[index] > a[index]) ? -1 : 0)
    }

    ascending(index) {
        return (a, b) => (b[index] > a[index]) ? 1 : ((a[index] > b[index]) ? -1 : 0)
    }

    getTagTableRows(documentsArray) {
        let rows = [];
        for(let index =0; index < documentsArray.length; index++) {
            let currentDocument = documentsArray[index];
            let currentRow = [];
            currentRow.push(currentDocument.fileName);
            currentRow.push(currentDocument.uploadedBy);
            currentRow.push(CommonHelper.localisedDate(this.props.user, currentDocument.createdAt));
            currentRow.push(this.getRowControlButtons(currentDocument));
            rows.push(currentRow);
        }
        return rows;
    }
    
    sortColumn = async (heading, tag) => {
        await this.setState(prevState => {
            const headingIndex = prevState.tableColumns.findIndex(x => x === heading);
            let sorting = Object.assign({}, prevState.sorting);
            if (sorting[tag]) {
                sorting[tag][0] = headingIndex
                // Second element determines whether the sorting is descending
                sorting[tag][1] = prevState.sorting[tag][0] === headingIndex ? !prevState.sorting[tag][1] : true
            }
            else {
                sorting[tag] = [headingIndex, true]
            }
            return { sorting }
        })
        this.populateTabPanes();
    }

    getTagTable(currentTag, key) {
        let clickedColumnIndex
        let sortingDirection
        if (this.state.sorting[currentTag]) {
            clickedColumnIndex = this.state.sorting[currentTag][0]
            sortingDirection = !this.state.sorting[currentTag][1]
        }
        else {
            clickedColumnIndex = 2
            sortingDirection = true
        }
        return (
            <React.Fragment key={key}>
                {this.getTableTagHeading(currentTag)}
                <SemTable
                    tag={currentTag}
                    sortable={true}
                    sortfunc={this.sortColumn}
                    headings={this.state.tableColumns}
                    rows={this.state.tablesAndRows[currentTag]}
                    rowcount={this.state.tablesAndRows[currentTag].length}
                    clickedcolumn={this.state.tableColumns[clickedColumnIndex]}
                    uniformColumns={true}
                    direction={sortingDirection ? 'ascending' : 'descending'}
                />
            </React.Fragment>
        );
    }

    getTableTagHeading(tagName) {
        return (<React.Fragment>
            <Header as='h3'><Icon name='tag'></Icon>{tagName}</Header>
        </React.Fragment>)
    }

    getRowControlButtons(document) {
        let isPdfDocument = this.isPdfDocument(document.fileName);
        let viewButtonStyle = { display : 'none'};
        if (isPdfDocument) {
            viewButtonStyle.display = 'inline';
        }
        return (<React.Fragment>
            <Button basic color='blue' onClick={() => { this.downloadDocument(document.id) }}>
                <Button.Content>
                    <Icon name='download'></Icon> Download
                </Button.Content>
            </Button>
            <Button basic color='blue' onClick={() => { this.viewDocument(document) }} style={viewButtonStyle}>
                <Button.Content>
                    <Icon name='eye'></Icon> View
                </Button.Content>
            </Button>
        </React.Fragment>)
    }


    downloadDocument(documentId) {
        var promise = organisationDocumentsService.getDocumentUrl(documentId)
            .then((result) => {
                window.location.href = result.data;
            });
        return promise;
    }

    viewDocument(clickedDocument) {
        this.setState({
            currentPopupModalDocument: clickedDocument,
            showDocumentViewModal: true
        });
    }

    closeViewDocumentModal() {
        this.setState({
            showDocumentViewModal: false
        });
    }

    isPdfDocument(fileName) {
        let array = fileName.split('.');
        let lastElement = array[array.length - 1];
        if (lastElement === 'pdf') {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
            <div>
                <Navbar handleClick={(event) => console.log(event.target)}/>
                <SidebarComponent content={this.renderPage()}/>
            </div>
        );
    }

    renderPage() {
        return (
            <div className='document-library'>
                <Grid stackable columns={1}>
                    <Grid.Row stretched>
                        <Grid.Column>
                            <Grid stackable columns={2}>
                                <Grid.Row>
                                    <Grid.Column width={12}>
                                        <h1>Document Library - { this.state.currentlySelectedOrganisationDetails.name }</h1>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <div className="actions">
                                            <Button primary onClick = { () => { window.history.back(); }}>
                                                <Button.Content visible>
                                                    Client Logbook
                                                </Button.Content>
                                            </Button>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Tab menu={{ secondary: true, pointing: true }} panes = { this.state.tabPanes } onTabChange = { this.handleTabChange } activeIndex = { this.state.selectedTabIndex } />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <DocumentList
                    organisationDocumentId = { this.state.currentPopupModalDocument.id }
                    show = { this.state.showDocumentViewModal }
                    closeModal = { this.closeViewDocumentModal }
                    showOrganisationDocument = { true }
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { component, oidc } = state;
    const { sidebarVisible } = component;
    const { user }  = oidc;
    return {
        sidebarVisible,
        user
    };
}

const connectedDocumentList = connect(mapStateToProps)(DocumentLibrary);
export { connectedDocumentList as DocumentLibrary };