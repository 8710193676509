const priorities = [
    {
        key: '1',
        text: 'Low',
        value: 'Low'
    },
    {
        key: '2',
        text: 'Medium',
        value: 'Medium'
    },
    {
        key: '3',
        text: 'High',
        value: 'High'
    }
];
const searchDateRange = 28;

class IssueHelper {
    static getSearchDateRange() {
        return searchDateRange;
    }

    static getIssuePriorities() {
        return priorities;
    }

    static getIssuePriorityDto(value) {
        let prioOrder;
        switch (value) {
            case 'High':
                prioOrder = 2;
                break;
            case 'Medium':
                prioOrder = 1;
                break;
            case 'Low':
                prioOrder = 0;
                break;
            default:
                prioOrder = null;
                break;
        }

        return {
            priority: value,
            priorityOrder: prioOrder
        };
    }
}

export default IssueHelper;
