import React from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'semantic-ui-react';

export default class SemRadio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e, {value}) {
        this.setState({ value });
        this.props.handleChange(value);
    }

    render() {
        return(
            <div className='form-group'>
                <Form>
                    {this.props.options.map(function(op, i) {
                        return(
                            <Form.Field key={i}>
                                <Radio
                                    className="radio"
                                    label={op.label}
                                    name='radioGroup'
                                    value={op.value}
                                    checked={this.state.value === op.value}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                        );
                    }, this)}
                </Form>
            </div>
        );
    }
}

SemRadio.propTypes = {
    handleChange: PropTypes.func,
    options: PropTypes.array
};
