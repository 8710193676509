import axios from 'axios';
import http from '../utils/api'

const organisationDocumentsApi = 'organisationdocuments';

//https://github.com/axios/axios#cancellation
const CancelToken = axios.CancelToken;

const getDocumentsForOrganisation = function (id) {
    let url = organisationDocumentsApi + '/organisation/' + id;

    let cancel;
    var promise = http
        .get(url, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(function(response) {
            return response;
        })
    promise.cancel = cancel;
    return promise;
}

const getDocumentUrl = function (documentId) {
    let url = organisationDocumentsApi + '/' + documentId;

    let cancel;
    var promise = http
        .get(url, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(function(response) {
            return response;
        })
    promise.cancel = cancel;
    return promise;
}

const getDocumentFile = function (documentId, getPdf, getBase64) {
    let url = organisationDocumentsApi + '/getdocumentfile?documentId=' + documentId;

    let cancel;
    var promise = http
        .get(url, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        })
        .then(function(response) {
            return response;
        })
    promise.cancel = cancel;
    return promise;
}

export const organisationDocumentsService = {
    getDocumentsForOrganisation,
    getDocumentUrl,
    getDocumentFile
}