import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ToolTip from './ToolTip.jsx';

export default class DropdownSearchable extends React.Component {
    constructor(props) {
        super(props);
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    handleSearchChange(e, { searchQuery }) {
        this.props.onSearchChange(searchQuery);
    }

    render() {
        let labelClass;
        this.props.required
            ? (labelClass = 'control-label required')
            : (labelClass = 'control-label');
        return (
            <div className="form-group">
                <label className={labelClass}>
                    {this.props.title} <ToolTip message={this.props.infoMsg} />
                </label>
                <Dropdown
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    floating
                    fluid
                    search={this.props.searchable}
                    selection
                    labeled
                    options={this.props.options}
                    onChange={this.props.onChange}
                    defaultValue={this.props.defaultValue}
                    value={this.props.value}
                    text={this.props.text}
                    multiple={this.props.multiple}
                    onSearchChange={this.handleSearchChange}
                    onScroll={this.props.onScroll}
                    onClick={this.props.onClick}
                />
            </div>
        );
    }
}

DropdownSearchable.propTypes = {
    options: PropTypes.array,
    title: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    value: PropTypes.any,
    text: PropTypes.string,
    searchable: PropTypes.bool,
    infoMsg: PropTypes.string,
    multiple: PropTypes.bool,
    onSearchChange: PropTypes.func,
    onScroll: PropTypes.func,
    onClick: PropTypes.func
};

DropdownSearchable.defaultProps = {
    searchable: true,
    disabled: false,
    multiple: false
};
