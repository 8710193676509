import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'
import { apiConfig } from '../_config'

export default class SiteVisitResults extends React.Component {
    render () {
        const siteVisitUrl = apiConfig.baseWebUrl+'/sitevisitresult/job/'+this.props.jobId;
        return (
            <div className="site-visit-container">
                <div className="site-visit-header">
                    <p>
                        View full page
                        <a href={siteVisitUrl} className="btn" rel="noopener noreferrer" target="_blank"><Icon name="chain"/></a>
                    </p>
                </div>
                <iframe src={siteVisitUrl} className="site-visit-iframe" title="Site visit report">
                    <p>Your browser does not support iframe's.</p>
                </iframe>
            </div>
        );
    }
}

SiteVisitResults.propTypes = {
    jobId: PropTypes.string
}