import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';


export default class BootstrapModal extends React.Component {
    render() {
        return (
            <Modal show={this.props.show}>
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="gray-bg">
                    {this.props.body}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.footer}
                    <button
                        onClick={() => this.props.btnClose()}
                        className="btn btn-default"
                    >
                        {this.props.txtCloseBtn}
                    </button>
                    <button onClick={() => this.props.btnSubmitEnabled && this.props.btnSubmit()} className="btn btn-outline btn-primary" disabled={!this.props.btnSubmitEnabled}>
                        {this.props.txtSubmitBtn}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

BootstrapModal.defaultProps = {
    txtCloseBtn: 'Cancel',
    txtSubmitBtn: 'Submit',
    btnSubmitEnabled: true
};

BootstrapModal.propTypes = {
    title: PropTypes.string,
    show: PropTypes.bool,
    body: PropTypes.any,
    footer: PropTypes.any,
    btnClose: PropTypes.func,
    btnSubmit: PropTypes.func,
    txtCloseBtn: PropTypes.string,
    txtSubmitBtn: PropTypes.string,
    btnSubmitEnabled: PropTypes.bool
};
