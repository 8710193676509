import http from '../utils/api'

const assetApi = 'assets';

const getAssetsOnSite = function(siteId) {
    return http.get(assetApi + '/onSite/' + siteId);
};

const getAssetsOnLocation = function(locationId) {
    return http.get(assetApi + '/onlocation', { params: { locationId: locationId } });
};

export const assetService = {
    getAssetsOnSite,
    getAssetsOnLocation
};