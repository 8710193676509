import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setSidebarVisibility } from '../_actions';

import _ from 'lodash';

class Navbar extends Component {
    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event, target) {
        event.preventDefault();

        switch (target.name) {
            case 'menu':
                this.props.dispatch(setSidebarVisibility(!this.props.sidebarVisible));
                break;
            default:
                console.log('implement handler for click:', target.name);
        }
    }

    render() {
        return (
            <div>
                <Menu>
                    <Menu.Item
                        name='menu'
                        onClick={this.handleClick}
                    >
                        <Icon name='bars' /> Menu
                    </Menu.Item>
                    <Menu.Item
                        position='right'
                        name="username"
                    >
                        {_.get(this.props, 'user.profile.name', '')}
                    </Menu.Item>
                </Menu>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { component, oidc } = state;
    const { sidebarVisible } = component;
    const { user } = oidc;
    return {
        sidebarVisible,
        user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

Navbar.defaultProps = {
    handleClick: () => console.log('implement me')
}

Navbar.propTypes = {
    handleClick: PropTypes.func
}