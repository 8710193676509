import http from '../utils/api'

const tagsApi = 'locations';

const getAllForParent = function(id) {
    return http.get(tagsApi + '/organisation/' + id + '/getall');
};

const createTag = function(tag, parentId) {
    const tagDto = {
        tag: tag,
        parentId: parentId
    };
    return http.post(tagsApi, tagDto);
};

const getTagsPage = function(parentId, page, limit, searchString) {
    const params = {
        parentId: parentId,
        page: page,
        limit: limit,
        searchString: searchString
    };

    return http.get(tagsApi + '/search', {
        params: params
    });
};

export const locationTagService = {
    getAllForParent,
    createTag,
    getTagsPage
};