import React, { Component } from 'react';
import { connect } from "react-redux";
import { SidebarComponent, Navbar, SiteCard } from '../../_components';
import { Search } from '../../_components/common';
import { getOrganisationsForUser } from '../../_actions';
import { appConstants } from '../../_constants';
import Paginator from '../../_components/common/Paginator.jsx';

class SiteList extends Component {
    constructor(props){
        super(props);

        this.renderPage = this.renderPage.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.performSearch = this.performSearch.bind(this);
        this.paginate = this.paginate.bind(this);

        this.state ={
            hasMore:true,
            isLoading:false,
            orgs: [],
            searchString: null,
            limit: 50,
            page: 0
        };
    }

    componentWillMount() {
        this.performSearch();
    }

    searchChange(event, data, label) {
        if(label !== 'search' || !data) return;
        
        this.setState({searchString: data.value});
    }
    performSearch(){
        this.props.getOrganisationsForUser(this.state.searchString, this.state.limit, this.state.page);
    }

    paginate(newpage) {
        this.setState({ page: newpage }, () => this.performSearch());
    }

    render() {
        return (
            <div>
                <Navbar handleClick={(event) => console.log(event.target)}/>
                <SidebarComponent content={this.renderPage()}/>
            </div>
        );
    }

    renderPage() {
        return (
            <div>
                <h1>Site List</h1>
                <div className="list-body site-list">
                    <Search
                        onChange={this.searchChange}
                        performSearch={this.performSearch}
                        placeholderText={'Name, Address, City, Postcode'}
                    />
                    {this.renderOrganisations()}
                </div>
            </div>
        );
    }

    renderPaginator(organisations) {
        if (!organisations.organisationsCount) return;
        return <Paginator
            page={this.state.page}
            limit={this.state.limit}
            paginate={this.paginate}
            rowcount={organisations.organisationsCount}
        />
    }

    renderNoneFound() {
        return <p>
            {appConstants.NO_SITES_FOUND}
        </p>
    }

    renderOrganisations() {
        const { organisations } = this.props;
        return (organisations && organisations !== undefined) ?
            <div>
                <div className="Dealers" id = "Dealers">
                    {organisations.organisationPageItems.map(
                        (org, i) => <SiteCard
                            key={i}
                            id={org.id}
                            image={org.image}
                            name={org.name}
                            address1={org.address1}
                            address2={org.address2}
                            address3={org.address3}
                            address4={org.address4}
                            city={org.city}
                            county={org.county}
                            zip={org.zip}
                            country={org.country}
                            dispatch={this.props.dispatch}
                        />
                    )}
                </div>

                {organisations.organisationsCount ? this.renderPaginator(organisations) : this.renderNoneFound()}
            </div>
            :
            <div></div>;
    }
}

function mapStateToProps(state) {
    const { component, oidc, organisation } = state;
    const { sidebarVisible } = component;
    const { user }  = oidc;
    const { organisations } = organisation;
    return {
        sidebarVisible,
        user,
        organisations
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        getOrganisationsForUser: (searchTerm, limit, page) => dispatch(getOrganisationsForUser(searchTerm, limit, page))
    };
}

const connectedSiteList = connect(mapStateToProps, mapDispatchToProps)(SiteList);
export { connectedSiteList as SiteList };