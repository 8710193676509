import React from 'react'
import zxcvbn from 'zxcvbn'
import { connect } from 'react-redux'
import { Input, Button } from 'semantic-ui-react'
import { push } from 'react-router-redux'
import { toast } from 'react-toastify';

import { getOrganisationLogo } from '../../_actions'
import { authConstants } from '../../_constants'

import _ from 'lodash'

import VelappityLogo from '../../assets/images/velappity-logo-small.png'
import PoweredByLogo from '../../assets/images/powered-by.png'
import { userService } from '../../services'
import { PulsionToast } from '../../_components'

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.handlePasswordReset = this.handlePasswordReset.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateConfirmPassword = this.validateConfirmPassword.bind(this)
        this.redirectToHomePage = this.redirectToHomePage.bind(this);

        this.minScore = 3;
        this.minLength = 8;

        this.state = {
            newPassword: '',
            strength: 0,
            passwordConfirmValid: true,
            logoUrl: this.props.logoUrl
        }
    }

    componentDidMount() {
        const { user, logoUrl } = this.props;
        var expired = !user || user.expired;
        if (expired){
            var tenant2 = localStorage[authConstants.LOCAL_TENANT_KEY];

            if (tenant2 && !logoUrl)
                this.props.getOrganisationLogo(tenant2);
        }
    }

    handlePasswordReset() {
        const userId = _.get(this.props, 'routeParams.id', '')
        const token = _.get(this.props, 'location.query.token', '');

        const { strength, newPassword } = this.state;
        if (newPassword.length < this.minLength) return toast.error("New password is too short");
        if (strength < this.minScore) return toast.error("New password is too weak");
        if (!this.state.passwordConfirmValid) return toast.error("Password confirmation must match");
        return userService.resetPassword(userId, token, this.state.newPassword, this.redirectToHomePage);
    }

    redirectToHomePage() {
        this.props.dispatch(push('/'));
    }

    handleChange(event, target) {
        const { value } = target;
        this.setState({
            newPassword: value,
            strength: zxcvbn(value).score
        });
    }

    validateConfirmPassword(event, { value }) {
        this.setState({
            passwordConfirmValid: (value === this.state.newPassword)
        });
    }

    render() {
        const { newPassword, strength } = this.state;
        const passwordLength = newPassword.length;
        const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();
        const poweredByCss = this.props.logoUrl ? '' : 'hidden';
        const logoCss = this.props.logoUrl ? '' : 'logo-pad';

        return (
            <div className="login-form">
                <PulsionToast />
                <div className="ui center aligned middle aligned grid">
                    <div className="column login-panel">
                        <img className={"ui centered image " + logoCss} src={_.get(this.props, 'logoUrl', VelappityLogo)} alt="Logo"></img>
                        <div className="ui centered header">
                            <h1 className="font">Reset Password</h1>
                        </div>
                        <div className="ui form form-panel">
                            <div className="ui segment">
                                <div className="header">
									Reset Your Password
                                </div>
                                <div className="ui divider"></div>
                                <div className="ui info message">
                                    <div className="header">
                                        Pick a new password
                                    </div>
                                    <ul className="list">
                                        <li>Choose a password at least 8 characters long.</li>
                                        <li>Use at least one number, letter and special character.</li>
                                    </ul>
                                </div>
                                <div className="one field">
                                    <div className="password-validator">
                                        <div className="field">
                                            <div className={strengthClass}>
                                                <div className="strength-meter-fill" data-strength={strength}></div>
                                            </div>
                                            <div className="ui fluid left icon input">
                                                <Input onChange={this.handleChange} name="password" type="password" placeholder="New Password" required icon='lock' iconPosition='left'></Input>
                                            </div>
                                        </div>
                                        <p className={this.state.passwordConfirmValid ? 'hidden' : 'error'}>Password confirm must be same as password</p>
                                        <div className="field">
                                            <div className="ui fluid left icon input">
                                                <Input onChange={this.validateConfirmPassword} type="password" placeholder="Repeat Password" required icon='lock' iconPosition='left'></Input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Button onClick={this.handlePasswordReset} className="ui large button fluid blue">Reset Password</Button>
                            </div>
                        </div>
                        <img src={PoweredByLogo} className={"ui centered image " + poweredByCss} alt="Powered-By" />
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { organisation } = state;
    const { logoUrl } = organisation;
    return {
        logoUrl
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        getOrganisationLogo: (id) => dispatch(getOrganisationLogo(id))
    };
}

const connectedResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
export { connectedResetPassword as ResetPassword };
