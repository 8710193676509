import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Navbar, SidebarComponent } from '../../_components'

class DashboardPage extends Component {
    render() {
        console.log('render dashboard');
        return (
            <div>
                <Navbar handleClick={(event) => console.log(event.target)}/>
                <SidebarComponent content={this.renderPage()}/>
            </div>
        )
    }

    renderPage() {
        return (
            <div>
                <h1>Dashboard</h1>
            </div>
        )
    }
}



function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        tenant: state.auth.tenant
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

const connectedDashboardPage = connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
export { connectedDashboardPage as DashboardPage };

