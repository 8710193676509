import http from '../utils/api'

const permissionsApi = 'permissions';

const getPermissionsForCurrentUser = () => {
    const url = `${permissionsApi}/currentuser`;
    return http.get(url)
        .then(response => {
            return response.data;
        });
}

export const permissionService = {
    getPermissionsForCurrentUser
}