import React from 'react';
import { Router, Route, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import store from './_helpers/store';

import { CallbackPage } from './_components';
import { HomePage, HoldingPage, DashboardPage, SiteList, DocumentLibrary, RequestPasswordReset, ResetPassword, Logbook, IssuesList, IssuePage, UnauthorisedPage, ForbiddenPage } from './Pages';

const history = syncHistoryWithStore(browserHistory, store);

export default function Routes() {
    return (
        <Router history={history}>
            <Route path="/" component={HomePage}/>
            <Route path="/callback" component={CallbackPage} />
            <Route path="/dashboard" component={DashboardPage} />
            <Route path="/sitelist" component={SiteList} />
            <Route path="/documentlibrary/:organisationId" component={DocumentLibrary}/>
            <Route path="/loggedout" component={HoldingPage} />
            <Route path="/requestpasswordreset" component={RequestPasswordReset} />
            <Route path="/resetpassword/:id" component={ResetPassword} />
            <Route path="/logbook/:name/:id" component={Logbook} />
            <Route path="/issueList" component={IssuesList} />
            <Route path="/issueCreate" component={IssuePage} />
            <Route path="/issueEdit/:id" component={IssuePage} />
            <Route path="/unauthorised" component={UnauthorisedPage}/>
            <Route path="/forbidden" component={ForbiddenPage}/>
        </Router>
    );
}
