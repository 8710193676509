import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { DropdownSearchable } from '../../_components/common';
import { issueTypesService, issuesService } from '../../services';
import PropTypes from 'prop-types';

export class IssueTypeSelector extends Component{
    constructor(props){
        super(props);
        this.selectionChanged = this.selectionChanged.bind(this);
        this.issueTypeSelected = this.issueTypeSelected.bind(this);

        this.state = {
            modalIsOpen: false,
            selectedIssueType: {}
        };
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.modalIsOpen!==prevState.modalIsOpen){
            return { modalIsOpen: nextProps.modalIsOpen};
        }
        else return null;
    }

    componentDidMount(){
        const self = this;
        issueTypesService.getAllIssueTypes().then(function(result) {
            const parsed = JSON.parse(result.data);
            const issueTypesDropdown = [];
            issuesService.convertToReactUsable(issueTypesDropdown, parsed.data);
            self.setState({
                issueTypesDropdown,
                issueTypes: parsed.data,
            });
        });
    }

    selectionChanged(value){
        const issueType = this.state.issueTypes.find(function(obj) {
            return obj.id === value;
        });
        this.setState({selectedIssueType: issueType});
    }

    issueTypeSelected(){
        this.props.issueTypeSelected(this.state.selectedIssueType);
    }

    render (){
        return (
            <Modal open={this.state.modalIsOpen}>
                <Modal.Header>
                    <h4 className="modal-title">Please Select</h4>
                </Modal.Header>
                <Modal.Content>
                    <DropdownSearchable
                        title="Issue Type"
                        options={this.state.issueTypesDropdown}
                        required={true}
                        value={this.state.issueTypeId}
                        onChange={(event, {value}) => this.selectionChanged(value) }
                        searchable={false}
                    />
                </Modal.Content>            
                <Modal.Actions>
                    <button
                        type="button"
                        className="btn btn-danger"
                        data-dismiss="modal"
                        onClick={() => this.props.selectionCanceled()}>
                        Close
                    </button>
                    <button
                        id="addItemButton"
                        className="btn btn-primary"
                        onClick={() => this.issueTypeSelected()}>
                        Submit
                    </button>
                </Modal.Actions>
            </Modal>
        );
    }
}

IssueTypeSelector.propTypes = {
    modalIsOpen: PropTypes.bool,
    issueTypeSelected: PropTypes.func,
    selectionCanceled: PropTypes.func
};