import { organisationConstants } from '../_constants';

const initialState = {
    response: {},
    error: ''
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case organisationConstants.ORGANISATION_LOGO_GET_REQUEST:
            return { ...state, response: null };
        case organisationConstants.ORGANISATION_LOGO_GET_SUCCESS:
            console.log('SETTING STATE RESPONSE TO');
            console.log(action.payload);
            return { ...state, logoUrl: action.payload };
        case organisationConstants.ORGANISATION_LOGO_GET_FAILURE:
            return { ...state, error: action.payload };



        case organisationConstants.ORGANISATIONS_GET_BY_USER_REQUEST:
            return { ...state, response: null };
        case organisationConstants.ORGANISATIONS_GET_BY_USER_SUCCESS:
            return { ...state, organisations: action.payload };
        case organisationConstants.ORGANISATIONS_GET_BY_USER_FAILURE:
            return { ...state, error: action.payload };
        default:
            return state;
    }
}
