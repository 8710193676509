console.log('env settings');
console.log(process.env);

const localdev = {
    baseUrl: "https://localhost:44300",
    baseApiUrl: "https://localhost:44300/api",
    baseWebUrl: "http://localhost:8089",
    jobSectionsUrl: "http://localhost:8090/",
    paymentsUrl: "http://localhost:3005/account-settings"
}

const development = {
    baseUrl: "https://velappity-dev-api.azurewebsites.net",
    baseApiUrl: "https://velappity-dev-api.azurewebsites.net/api",
    baseWebUrl: "https://velappity-dev.azurewebsites.net",
    jobSectionsUrl: "https://velappity-dev-components.azurewebsites.net",
    paymentsUrl: "https://velappity-dev-payments.azurewebsites.net"
}

const test = {
    baseUrl: "https://velappity-test-api.azurewebsites.net",
    baseApiUrl: "https://velappity-test-api.azurewebsites.net/api",
    baseWebUrl: "https://velappity-test.azurewebsites.net",
    jobSectionsUrl: "",
    paymentsUrl: ""
}

const productionRc = {
    baseUrl: "https://velappity-prod-api-rc.azurewebsites.net",
    baseApiUrl: "https://velappity-prod-api-rc.azurewebsites.net/api",
    baseWebUrl: "https://velappity-prod-web-rc.azurewebsites.net",
    jobSectionsUrl: "",
    paymentsUrl: ""
}

const production = {
    baseUrl: "https://app-api.velappity.com",
    baseApiUrl: "https://app-api.velappity.com/api",
    baseWebUrl: "https://app.velappity.com",
    jobSectionsUrl: "",
    paymentsUrl: ""
}

let environmentConfig = {};

switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'localdev':
    default:
        environmentConfig = localdev;
        break;
    case 'development':
        environmentConfig = development
        break;
    case 'test':
        environmentConfig = test;
        break;
    case 'productionRc':
        environmentConfig = productionRc;
        break;
    case 'production':
        environmentConfig = production;
        break;
}

export const apiConfig = {
    ...environmentConfig
}