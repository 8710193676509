import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

export default class SemPopup extends React.Component {
    render() {
        return(
            <Popup content={this.props.content} text={this.props.text} />
        );
    }
}

SemPopup.propTypes = {
    content: PropTypes.any,
    text: PropTypes.string    
};
