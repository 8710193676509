import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, Dropdown } from 'semantic-ui-react';
import { ExtendedTextInput, DropdownSearchable, Category } from '../common';
import IssueHelper from '../../_helpers/IssueHelper.js';

export class IssueDetails extends Component {
    handleChange(event, {value}, label) {
        if (label === 'priority') {
            const priorityDto = IssueHelper.getIssuePriorityDto(value);
            this.props.setIssueProperty(priorityDto, label);
        } else {
            this.props.setIssueProperty(value, label);
        }
    }

    getStateIconName(state) {
        if (!state) {
            return '';
        }

        switch (state.toLowerCase()) {
            case 'open':
                return 'write';
            case 'on hold':
                return 'pause circle outline';
            default:
                // closed
                return 'checkmark';
        }
    }

    getStateIcon(state) {
        if (!state) {
            return '';
        }

        switch (state.toLowerCase()) {
            case 'open':
                return <Icon name={this.getStateIconName(state)} />;
            case 'on hold':
                return <Icon name="pause circle outline" />;
            default:
                // closed
                return <Icon name="checkmark" />;
        }
    }
    getStateDisplayText(state) {
        if (!state) {
            return '';
        }
        switch (state.toLowerCase()) {
            case 'open':
                return 'Open';
            case 'on hold':
                return 'On Hold';
            default:
                // closed
                return 'Closed';
        }
    }

    getTransitionDisplayText(state, transitionName) {
        if (!state) {
            return null;
        }

        return (
            <div>
                {this.getStateDisplayText(state)}
                <span className="issue-transition-name">({transitionName})</span>
            </div>
        );
    }

    getStateControl() {
        const state = this.props.issueStatus;
        if (!state) {
            return null;
        }

        var availableTransitions = this.getAvailableTransitions();

        return (
            <Dropdown
                trigger={this.getStateDisplayText(state)}
                options={availableTransitions}
                selectOnBlur={false}
                icon={this.getStateIconName(state)}
                button
                className="btn btn-outline btn-primary"
                onChange={(event, data) => this.handleChange(event, data, 'state')}
            />
        );
    }

    getAvailableTransitions() {
        // TODO: this really should be a call to the microservice to get all available transitions
        var options = [
            {
                key: 'instructions',
                text: (
                    <span>
                        Transition from{' '}
                        <strong>{this.getStateDisplayText(this.props.issueStatus)}</strong> to:
                    </span>
                ),
                disabled: true
            }
        ];

        const issueState = this.props.issueStatus;
        if (!issueState) {
            return options;
        }

        switch (issueState.toLowerCase()) {
            case 'open':
                options.push({
                    key: 'on hold',
                    icon: this.getStateIconName('on hold'),
                    text: this.getTransitionDisplayText('on hold', 'Place On-Hold'),
                    value: 'On Hold'
                });
                options.push({
                    key: 'closed',
                    icon: this.getStateIconName('closed'),
                    text: this.getTransitionDisplayText('closed', 'Close Issue'),
                    value: 'Closed'
                });
                break;
            case 'on hold':
                options.push({
                    key: 'open',
                    icon: this.getStateIconName('open'),
                    text: this.getTransitionDisplayText('open', 'Re-Open'),
                    value: 'Open'
                });
                break;
            default:
                // closed
                options.push({
                    key: 'open',
                    icon: this.getStateIconName('open'),
                    text: this.getTransitionDisplayText('open', 'Re-Open'),
                    value: 'Open'
                });
                break;
        }
        return options;
    }

    render() {
        let statusControl = this.getStateControl();

        return (            
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={2}>
                        <p>Priority</p>
                        {!this.props.editMode?
                            <div className="categoy-inline">
                                <Category 
                                    showCategoryText
                                    category={this.props.issuePriority} 
                                    availableCategories={["high", "medium", "low"]}
                                    stylePrefix="sqr-priority-"
                                />
                            </div>
                            :
                            <DropdownSearchable
                                className="form-control"
                                required={true}
                                options={IssueHelper.getIssuePriorities()}
                                value={this.props.issuePriority}
                                onChange={(event, data) => this.handleChange(event, data, 'priority')}
                            />
                        }
                        
                    </Grid.Column>
                    {this.props.issueStatus === undefined ? null:
                        <Grid.Column width={11}>
                            <p>Status</p>
                            {!this.props.editMode?
                                <div className="categoy-inline">
                                    <Category 
                                        showCategoryText
                                        category={this.props.issueStatus} 
                                        availableCategories={["open", "closed", "onhold"]} 
                                    />
                                </div>
                                : 
                                <div>
                                    {statusControl}
                                </div>
                            }
                        </Grid.Column>
                    }
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <p>Description</p>
                        {!this.props.editMode?
                            <div className="border-top">
                                {this.props.issueDescription}
                            </div>
                            :
                            <ExtendedTextInput
                                className="form-control"
                                required={true}
                                value={this.props.issueDescription}
                                onChange={(event, data) =>
                                    this.handleChange(event, data, 'description')
                                }
                                placeholder={this.props.issueDescription || 'Description'}
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
                {!this.props.issueValue ? null : (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <p>Description</p>
                            <div className="border-top">
                                {this.props.issueDescription}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        );
    }
}

IssueDetails.propTypes = {
    editMode: PropTypes.bool,
    issueDescription: PropTypes.string,
    issuePriority: PropTypes.string,
    issueStatus: PropTypes.string,
    issueValue: PropTypes.string,
    setIssueProperty: PropTypes.func,
    //setIssueStatus: PropTypes.func,
    onChange: PropTypes.func
};
