import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import auth from './auth.reducer';
import component from './component.reducer';
import organisation from './organisation.reducer';
import job from './job.reducer';

const reducer = combineReducers({
    routing: routerReducer,
    oidc: oidcReducer,
    auth,
    component,
    organisation,
    job
});

export default reducer;
