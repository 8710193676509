import http from '../utils/api'

const jobApi = 'jobs';

const getCompletedJobsForSiteAndYear = (siteId, year) => {
    const url = `${jobApi}/getcompletedjobsbysiteandyear/${siteId}/${year}`;
    return http.get(url)
        .then(response => {
            return response.data;
        });
}

const canGetPdf = function(id, callback) {
    return http.get(jobApi + '/cangetpdf/' + id);
};

const getPdf = function(id) {
    return http.get(jobApi + '/getpdf/' + id);
};
const getPdfBase64 = function(id) {
    return http.get(jobApi + '/getpdf/' + id +'/base64');
};

const GetDocListForClient = function(jobId){
    return http.get(jobApi + '/getdoclistforclient/' + jobId);
}

export const jobService = {
    getCompletedJobsForSiteAndYear,
    canGetPdf,
    getPdf,
    getPdfBase64,
    GetDocListForClient
}