import http from '../utils/api'
import { appConstants } from '../_constants';
import {CommonHelper, PromiseHelper}  from '../_helpers';

const organisationApi = 'organisations';

const getOrganisationLogo = (orgId) => {
    const url = `${organisationApi}/${orgId}/image`;

    return http.get(url).then(response => {
        return response.data;
    });
}

const getOrganisationsForUser = (searchTerm, limit, page) => {
    const params = { searchTerm, limit, page };
    const queryString = CommonHelper.encodeAsQueryString(params);
    const url = `${organisationApi}/lowestorgs/byuser?${queryString}`;

    return PromiseHelper.getCancelableHttpGetPromise(url);
}

const getImmediateParentsOfSites = function(take, skip) {
    let url = organisationApi + '/parentsofsites/' + take;
    if (skip) url = url + '?skip=' + skip;

    return PromiseHelper.getCancelableHttpGetPromise(url);
}

const getAllParents = function(id) {
    return http.get(organisationApi + '/allParents/' + id);
}

const getImmediateParentsOfSitesBySearch = function(text, limit, skip) {
    let url = organisationApi + '/parentsofsites/search/' + text + '?';
    if (limit) url = url + 'limit=' + limit + '&';
    if (skip) url = url + 'skip=' + skip + '&';

    return PromiseHelper.getCancelableHttpGetPromise(url);
};

const getSitesForOrganisationSearch = function(id, text) {
    const url = organisationApi + '/sitesfororganisation/' + id + '/search/' + text;
    return PromiseHelper.getCancelableHttpGetPromise(url);
};

const getSitesForOrganisationLimited = function(id, max) {
    return http.get(organisationApi + '/sitesfororganisation/' + id + '/limitedto/' + max);
};

const getSitesForOrganisationBySearchLimited = function(id, text, max = appConstants.ITEMS_IN_SELECT, skip) {
    var url = organisationApi + '/getSitesForOrganisation/' + id + '/dropdown?maxCount=' + max;
    if (text !== null && text !== undefined) url = url + '&searchString=' + text;
    if (skip !== null && skip !== undefined) url = url + '&skip=' + skip;

    return PromiseHelper.getCancelableHttpGetPromise(url);
};

const getOrganisationDetails = function(organisationId, returnDocumentList) {
    var url = organisationApi + '/' + organisationId + '?returnDocumentList=' + returnDocumentList + '&visibleOnPortal=true';
    return PromiseHelper.getCancelableHttpGetPromise(url);
}

export const organisationService = {
    getOrganisationLogo,
    getOrganisationsForUser,
    getAllParents,
    getImmediateParentsOfSites,
    getImmediateParentsOfSitesBySearch,
    getSitesForOrganisationLimited,
    getSitesForOrganisationSearch,
    getSitesForOrganisationBySearchLimited,
    getOrganisationDetails
}