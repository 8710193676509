import axios from 'axios';
import { toast } from 'react-toastify'

import { appConstants } from '../_constants'
import { apiConfig } from '../_config';

const usersApi = apiConfig.baseApiUrl + '/users';

const requestPasswordResetEmail = (emailAddress) => {
    const emailDto = {
        Email: emailAddress,
        ClientType: 'portal'
    };

    const url = usersApi + '/requestpasswordreset';
    console.log('POST', url);
    return axios.post(usersApi + '/requestpasswordreset', emailDto)
    // we could maybe move .then, .catch.. to outside the service, let the component that called it handle?
    // I think here is nicer but worth thinking about, as Angular services eg return just the promise
        .then(function(response) {
            toast.success(appConstants.PASSWORD_EMAIL_SENT_SUCCESS_MESSAGE + emailAddress);
        })
        .catch(function(error) {
            console.log(error);
            toast.error(error.message);
        });
}

const resetPassword = (userId, token, newPassword, callback) => {
    const passwordResetDto = {
        UserId: userId,
        Token: token,
        NewPassword: newPassword
    };
    const url = usersApi + '/resetpassword';
    console.log('POST', url);
    return axios.post(url, passwordResetDto)
        .then(response => {
            toast.success(appConstants.PASSWORD_EMAIL_SENT_SUCCESS_MESSAGE);
            callback();
        })
        .catch(error => {
            console.log(error);
            toast.error(error.message);
        });
}

export const userService = {
    requestPasswordResetEmail,
    resetPassword
}