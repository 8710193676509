import React, { Component } from 'react';
import { Document, Page } from 'react-pdf/dist/entry.noworker';
import { Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import 'react-pdf/dist/Page/AnnotationLayer.css';

class PdfViewer extends Component {
    constructor(props) {
        super(props);

        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);

        this.state = {
            numPages: null,
            pageNumber: 1,
        }
    }
    

    componentDidUpdate(prevProps) {
        //user has selected a new file.
        if(prevProps.file !== this.props.file){
            this.setState({
                numPages: null,
                pageNumber: 1
            });
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    nextPage() {
        let pageNum = this.state.pageNumber;
        if (pageNum === this.state.numPages) return;

        this.setState({
            pageNumber: ++pageNum
        });
    }
    prevPage() {
        let pageNum = this.state.pageNumber;
        if (pageNum === 1) return;

        this.setState({
            pageNumber: --pageNum
        });
    }

    render() {
        const { pageNumber, numPages } = this.state;

        return (
            <div>
                <div className="pdf-header">
                    <p>
                        Page {pageNumber} of {numPages}
                        {pageNumber > 1 ?
                            <button className="btn" onClick={this.prevPage} title="Previous Page"><Icon name="angle left"/></button>:
                            null
                        }
                        {pageNumber === numPages ?
                            null: 
                            <button className="btn" onClick={this.nextPage} title="Next Page"><Icon name="angle right"/></button>
                        }

                        <button className="btn" onClick={this.props.download} title="Download PDF"><Icon name="download"/></button>
                    </p>
                </div>
                <div className="pdf-body">
                    <Document
                        file={this.props.file}
                        onLoadSuccess={this.onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                </div>
            </div>
        );
    }
}

PdfViewer.propTypes = {
    file: PropTypes.string,
    download: PropTypes.func
}

export default PdfViewer;