import axios from 'axios';
import http from '../utils/api'

class PromiseHelper{
    
    //https://github.com/axios/axios#cancellation
    static getCancelableHttpGetPromise = (url) => {
        const CancelToken = axios.CancelToken;
        let cancel;

        let promise = http.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        promise.cancel = cancel;
        return promise;
    }
}

export default PromiseHelper;