import http from '../utils/api'

const issueTypesApi = 'issueTypes';

const getAllIssueTypes = function() {
    return http.get(issueTypesApi);
};

export const issueTypesService = {
    getAllIssueTypes
}