import { jobService } from '../services'
import { jobConstants } from '../_constants'

const getCompletedJobsForSiteAndYear = (id, year) => {
    const request = () => { return { type: jobConstants.JOBS_GET_BY_SITE_AND_YEAR_REQUEST } };
    const success = data => { return { type: jobConstants.JOBS_GET_BY_SITE_AND_YEAR_SUCCESS, payload: data } };
    const failure = error => { return { type: jobConstants.JOBS_GET_BY_SITE_AND_YEAR_FAILURE, payload: error } };

    return dispatch => {
        dispatch(request());

        jobService.getCompletedJobsForSiteAndYear(id, year).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    }
}

export {
    getCompletedJobsForSiteAndYear
};