import { organisationService } from '../services'
import { organisationConstants } from '../_constants'

var promise = null;
const cancelLastRequest = () =>{
    if(promise !== null)
        promise.cancel('Search Cancelled');
}

const getOrganisationLogo = (id) => {
    const request = () => { return { type: organisationConstants.ORGANISATION_LOGO_GET_REQUEST } };
    const success = data => { 
        return { type: organisationConstants.ORGANISATION_LOGO_GET_SUCCESS, payload: data } 
    };
    const failure = error => { 
        promise = null;
        return { type: organisationConstants.ORGANISATION_LOGO_GET_FAILURE, payload: error } 
    };
    return dispatch => {
        // set response to null
        dispatch(request());

        organisationService.getOrganisationLogo(id).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    }
}



const getOrganisationsForUser = (searchTerm, limit, page) => {
    const request = () => { return { type: organisationConstants.ORGANISATIONS_GET_BY_USER_REQUEST } };
    const success = data => { 
        promise = null;
        return { 
            type: organisationConstants.ORGANISATIONS_GET_BY_USER_SUCCESS, payload: data 
        } 
    };
    const failure = error => { 
        promise = null;
        return { 
            type: organisationConstants.ORGANISATIONS_GET_BY_USER_FAILURE, payload: error 
        } 
    };

    return dispatch => {
        dispatch(request());
        cancelLastRequest();
        promise = organisationService.getOrganisationsForUser(searchTerm, limit, page)
        
        promise.then(
            response => dispatch(success(response.data)),
            error => dispatch(failure(error))
        );
    }
}

export {
    getOrganisationLogo,
    getOrganisationsForUser
};