import http from '../utils/api'

const tenantFeatureApi = 'tenantfeature';

const getTenantFeatures = () => {
    const url = `${tenantFeatureApi}`;
    return http.get(url)
        .then(response => {
            return response.data;
        });
}

export const tenantFeatureService = {
    getTenantFeatures
}