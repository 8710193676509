import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Icon } from 'semantic-ui-react';
import ToolTip from './ToolTip.jsx';

export default class TextInput extends Component {
    render() {
        if (this.props.error) {
            const tooltipContent = <Icon link name="warning" color="red" />;
            this.icon = <ToolTip message={this.props.errMsg} content={tooltipContent} />;
        } else {
            this.icon = null;
        }

        let labelClass;
        this.props.required
            ? (labelClass = 'control-label required')
            : (labelClass = 'control-label');

        return (
            <div className="form-group">
                <label className={labelClass}>{this.props.title}</label>
                <label>
                    <ToolTip message={this.props.infoMsg} />
                </label>
                <Input
                    type={this.props.type}
                    className= {this.props.className +" semantic-control"}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    onKeyPress={this.props.onKeyPress}
                    disabled={this.props.disabled}
                    value={this.props.value}
                    error={this.props.error}
                    icon={this.icon}
                /> 
            </div>
        );
    }
}

TextInput.propTypes = {
    required: PropTypes.bool,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.any,
    infoMsg: PropTypes.string,
    errMsg: PropTypes.string,
    error: PropTypes.bool,
    className: PropTypes.string,
};
