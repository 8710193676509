import React from 'react'
import PropTypes from 'prop-types'

export default class Spinner extends React.Component {
    render () {
        return (
            <div className="ui grid">
                <div className="ui active inverted dimmer">
                    <div className="ui text loader">{this.props.caption}</div>
                </div>
                <p></p>
            </div>
        );
    }
}

Spinner.defaultProps = {
    caption: 'Loading...'
}
Spinner.propTypes = {
    caption: PropTypes.string,
}