import React, { Component } from 'react'
import userManager from '../../utils/userManager'

import { Navbar, SidebarComponent } from '../../_components'

export class UnauthorisedPage extends Component {
    async componentDidMount(){
        const user = await userManager.getUser();
        if (!user || user.expired){
            userManager.removeUser().then(function(){
                userManager.signinRedirect();
            });
        }
    }

    render() {
        return (
            <div>
                <Navbar handleClick={(event) => console.log(event.target)}/>
                <SidebarComponent content={this.renderPage()}/>
            </div>
        )
    }

    renderPage() {
        return (
            <div>
                <h1>401 - Unauthorised</h1>
            </div>
        )
    }
}
