export const appConstants = {
    TOGGLE_SIDEBAR_VISIBLE: 'TOGGLE_SIDEBAR_VISIBLE',
    TOAST_DEFAULT_DISPLAY_TIME: 2500,
    PASSWORD_EMAIL_SENT_SUCCESS_MESSAGE: 'The password reset email was successfully sent to ',
    PASSWORD_RESET_SUCCESS: 'Password reset successfully',
    PAGINATION_TABS_TOTAL: 10,
    PAGINATION_TABS_FIRSTHALF: 5,
    PAGINATION_TABS_LASTHALF: 4,
    ITEMS_IN_SELECT: 10,
    NO_SITES_FOUND: 'No Sites found.'
};