import React from "react";
import { connect } from "react-redux";
import { authConstants } from '../../_constants';
import _ from 'lodash';

import { identityService } from '../../services'

class HoldingPage extends React.Component {
    constructor(props){
        super(props);
        this.login = this.login.bind(this);
    }

    login(){
        var tenant = _.get(this.props, 'tenant', '');

        if (!tenant) tenant = localStorage[authConstants.LOCAL_TENANT_KEY];
        identityService.redirectSignIn(tenant);
    }

    render() {
        this.login();
        return  <div className="App-header">
            <h2>Redirecting...</h2>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        tenant: state.auth.tenant
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

const connectedHoldingPage = connect(mapStateToProps, mapDispatchToProps)(HoldingPage);
export { connectedHoldingPage as HoldingPage };
