import React from 'react';
import { connect } from 'react-redux'
import { Input, Button } from 'semantic-ui-react'
import _ from 'lodash';

import { validationConstants, authConstants } from '../../_constants'
import { userService } from '../../services'
import { PulsionToast } from '../../_components'
import { getOrganisationLogo } from '../../_actions'

import VelappityLogo from '../../assets/images/velappity-logo-small.png'
import PoweredByLogo from '../../assets/images/powered-by.png'

class RequestPasswordReset extends React.Component {
    constructor(props) {
        super(props);

        this.emailChanged = this.emailChanged.bind(this);
        this.requestPasswordReset = this.requestPasswordReset.bind(this);

        this.state = {
            email: '',
            emailValid: false
        }
    }

    componentDidMount() {
        const { user } = this.props;
        var expired = !user || user.expired;
        if (expired){
            var tenant2 = localStorage[authConstants.LOCAL_TENANT_KEY];

            if (tenant2 && !this.props.logoUrl)
                this.props.getOrganisationLogo(tenant2);
        }
    }

    emailChanged(event, { value }) {
        const valid = validationConstants.EmailRegEx.test(value);
        this.setState({
            email: value,
            emailValid: valid
        });
    }

    requestPasswordReset(event, target) {
        event.preventDefault();
        userService.requestPasswordResetEmail(this.state.email);
    }

    render() {
        const logoCss = this.props.logoUrl ? '' : 'logo-pad';
        const poweredByCss = this.props.logoUrl ? '' : 'hidden';

        const showEmailErrorMessage = !this.state.emailValid && _.get(this.state, 'email', '').length > 0;
        // TODO: reset password and request password reset pages very similar div/style wise
        // extract into single component (form page?) and just handle the onClick/onChange etc. differently
        return(
            <div>
                <PulsionToast />
                <div className="login-form">
                    <div className="ui center aligned middle aligned grid">
                        <div className="column login-panel">
                            <img className={"ui centered image " + logoCss} src={_.get(this.props, 'logoUrl', VelappityLogo)} alt="Logo"></img>
                            <div className="ui centered header">
                                <h1 className="font">Reset Password</h1>
                            </div>
                            <form className="ui form form-panel">
                                <div className="ui segment">
                                    <div className="header">
                                        Please enter your email address
                                    </div>
                                    <div className="ui divider"></div>
                                    <div className="one field">
                                        <div className="field">
                                            <div className="ui fluid left icon input">
                                                <Input placeholder="Email" required onChange={this.emailChanged}></Input>
                                            </div>
                                        </div>
                                    </div>
                                    <p className={showEmailErrorMessage ? 'error' : 'hidden'}>Please enter a valid email address</p>
                                    <Button primary className="ui large button submit fluid blue" disabled={!this.state.emailValid || _.get(this.state, 'email', '').length <= 0} onClick={this.requestPasswordReset}>Reset Password</Button>
                                </div>
                            </form>
                            <img src={PoweredByLogo} className={"ui centered image " + poweredByCss} alt="Powered-By" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { oidc, auth, organisation } = state;
    const { user }  = oidc;
    const { tenant } = auth;
    const { logoUrl } = organisation;
    return {
        user,
        tenant,
        logoUrl
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        getOrganisationLogo: (id) => dispatch(getOrganisationLogo(id))
    };
}

const connectedRequestPasswordReset = connect(mapStateToProps, mapDispatchToProps)(RequestPasswordReset);
export { connectedRequestPasswordReset as RequestPasswordReset };
