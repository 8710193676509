import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Responsive } from 'semantic-ui-react';
import Paginator from './Paginator.jsx';
import _ from 'lodash';

export default class SemTable extends Component {
    constructor(props) {
        super(props);
        this.getEmptyTableJSX = this.getEmptyTableJSX.bind(this);
        this.getTableBodyJSX = this.getTableBodyJSX.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.renderSectionHeading = this.renderSectionHeading.bind(this);
        this.getHeadings = this.getHeadings.bind(this);
        this.getEmptyHeadings = this.getEmptyHeadings.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    updateDimensions() {
        // force a rerender on resize
        // ensures the subheading colSpan updates appropriately
        this.forceUpdate();
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    getEmptyTableJSX() {
        return (
            <Table.Body>
                <Table.Row className="text-table-center">
                    <Table.Cell colSpan={this.props.headings.length}>
                        <div> {this.props.noDataMessage} </div>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        );
    }

    /*
        render many rows
        rowArray = [ [row], [row], [row]]
    */
    renderRowArray(rowArray) {
        return (rowArray.map((row, q) => {
            return <Table.Row key={q}>
                {this.renderRow(row)}
            </Table.Row>;
        }));
    }

    /*
        Render a row
        row = [cell1, cell2, cell3, cell4.....]
    */
    renderRow(row) {
        return (row.map((rowEntry, q) => {
            return (
                <Responsive minWidth={_.get(rowEntry, 'minWidth', null)} as={Table.Cell} key={q} className={_.get(rowEntry, 'customStyle', '')}>
                    <div>{_.get(rowEntry, 'data', rowEntry)}</div>
                </Responsive>
            );
        }));
    }

    renderSectionHeading(heading) {
        const currentWindowWidth = window.innerWidth;
        var shownHeadings = _.filter(this.props.headings, function(h) {
            var width = _.get(h, 'minWidth', 0);
            return width <= currentWindowWidth;
        });

        return (<Table.Row className="group">
            <Table.Cell colSpan={shownHeadings.length}>
                <h3>{heading}</h3>
            </Table.Cell>
        </Table.Row>);
    }

    getTableBodyJSX() {
        if (this.props.enableSubsections) {
            return this.props.rows.sections.map((section, i) => {
                return <Table.Body key={i}>
                    {this.renderSectionHeading(section.heading)}
                    {this.renderRowArray(section.rows)}
                </Table.Body>;
            });
        } else {
            return <Table.Body>
                {this.renderRowArray(this.props.rows)}
            </Table.Body>;
        }
    }

    getHeadings() {
        return <Table.Header>
            <Table.Row>
                {this.props.headings.map((heading, i) => {
                    // "old" implementation -> heading is just heading
                    // "new" implementation -> heading is { data: heading, style: styling, minWidth: xyz }
                    const headingText = _.get(heading, 'data', heading);
                    return (
                        <Responsive
                            minWidth={_.get(heading, 'minWidth', 0)}
                            as={Table.HeaderCell}
                            key={i}
                            onClick={(this.props.sortable) ? () => this.props.sortfunc(headingText, this.props.tag) : null}
                            sorted={this.props.clickedcolumn === headingText ? this.props.direction : null}
                            className={'text-nowrap text-navy ' + _.get(heading, 'customStyle', '')}
                        >
                            {headingText}
                        </Responsive>
                    );
                })}
            </Table.Row>
        </Table.Header>;
    }

    getEmptyHeadings() {
        return <Table.Row />;
    }

    render() {
        if (this.props.pagination && this.props.rowcount) {
            this.paginator = <Paginator {...this.props} />;
        } else {
            this.paginator = null;
        }

        let tableHeading = this.props.headings ? this.getHeadings() : this.getEmptyHeadings();

        let tableBody;
        if (this.props.rows && this.props.rowcount) {
            tableBody = this.getTableBodyJSX();
        } else {
            tableBody = this.getEmptyTableJSX();
        }

        // To make table cells of the same size, add  <Table columns={this.props.headings.length} or <Table fixed...
        return (
            <div>
                <Table fixed={this.props.uniformColumns} sortable={this.props.sortable} unstackable={this.props.unstackable} celled={false} padded striped={false} selectable>
                    {tableHeading}
                    {tableBody}
                </Table>
                {this.paginator}
            </div>
        );
    }
}

SemTable.defaultProps = {
    sortable: true,
    enableSubsections: false,
    stackable: false,
    unstackable: false,
    uniformColumns: false,
    noDataMessage: 'No data available in table',
    clickedcolumn: '',
    direction: 'ascending'
};

SemTable.propTypes = {
    headings: PropTypes.array,
    rows: PropTypes.any,
    sortfunc: PropTypes.func,
    pagination: PropTypes.bool,
    rowcount: PropTypes.number,
    sortable: PropTypes.bool,
    sectionedRows: PropTypes.object,
    enableSubsections: PropTypes.bool,
    stackable: PropTypes.bool,
    unstackable: PropTypes.bool,
    uniformColumns: PropTypes.bool,
    noDataMessage: PropTypes.string,
    clickedcolumn: PropTypes.string,
    direction: PropTypes.string
};
