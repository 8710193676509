import { apiConfig } from '../_config'
import userManager from '../utils/userManager'

const redirectSignIn = (tenant) => {
    if (tenant)
        userManager.signinRedirect({ acr_values: 'tenant:' + tenant +' apiurl:'+ apiConfig.baseApiUrl });
    else
        userManager.signinRedirect();
}

export const identityService = {
    redirectSignIn
}