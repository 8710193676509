import _ from 'lodash';
import momentL from 'moment/min/moment-with-locales' 

const itemsPerPage = 10;

class CommonHelper {
    static getItemsPerPage() {
        return itemsPerPage;
    }

    static isNullOrUndefined(value) {
        return (_.isNull(value) || _.isUndefined(value));
    }

    static areStringsEqual(string1, string2) {
        return (string1.toUpperCase() === string2.toUpperCase());
    }

    static arrayToBase64(array) {
        const s = JSON.stringify(array);
        return btoa(s);
    }

    static arrayContainsOneOrMoreStrings(array, ...strings){
        if(!array || !strings)
            return false;

        for(let x = 0; x < strings.length; x++){
            if(array.indexOf(strings[x]) > -1){
                return true;
            }
        }
        return false;
    }

    static getLocate(user){
        return _.get(user, 'profile.locale', 'en-GB');
    }

    static getLocalDateFormat(user, includeTime){
        momentL.locale(this.getLocate(user));
        return includeTime === true
            ? momentL.localeData().longDateFormat('L') + ' HH:mm'
            : momentL.localeData().longDateFormat('L');
    }

    static localisedDate(user, date) {
        return momentL(date)
            .locale(this.getLocate(user))
            .format(this.getLocalDateFormat(user, true));
    }

    static encodeAsQueryString(paramsObject) {
        return Object
            .keys(paramsObject)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent([paramsObject[key]])}`)
            .join('&');
    }
}

export default CommonHelper;
