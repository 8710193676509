import { authConstants } from '../_constants';

const setTenantQuery = function(id) {
    return {
        type: authConstants.SET_TENANT_QUERY,
        payload: id
    };
}
const setPermissions = function(permissions){
    return { 
        type: authConstants.SET_PERMISSIONS, 
        payload: permissions 
    }
}

const setFeatures = function(features){
    return { 
        type: authConstants.SET_FEATURES, 
        payload: features 
    }
}

const clearFeaturesAndPermissions = function(){
    return { 
        type: authConstants.CLEAR_FEATURES_AND_PERMISSIONS 
    }
}

export {
    setTenantQuery,
    setPermissions,
    setFeatures,
    clearFeaturesAndPermissions
};