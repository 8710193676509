import React from 'react'
import PropTypes from 'prop-types'
import placeholderLogo from '../assets/images/placeholder.svg'
import { push } from 'react-router-redux'

const SiteCard = (props) => {
    return(
        <div className="Dealership" onClick={() => props.dispatch(push('/logbook/' + encodeURIComponent(props.name) + '/' + props.id))}>
            <img className = "picture" src = {props.image || placeholderLogo} alt = ""/>
            <div className = "Details">
                <h2 className = "Name">{props.name}</h2>
                <p className = "AddressFirstLine"> {props.address1}
                    {props.address2 &&
                        <span className = "AddressSecondLine">, {props.address2} </span>
                    }
                </p>
                {
                    (props.address3 || props.address4) &&
                    <p className = "AddressThirdLine">
                        {props.address3 && <span>{props.address3}</span>}
                        {
                            props.address4 &&
                            <span className = "AddressFourthLine">, {props.address4} </span>
                        }
                    </p>
                }
                {
                    (props.city || props.county || props.zip) &&
                    <p className="AddressPostTownAndPostCode">
                        {props.city && <span>{props.city}</span>}
                        {props.county && <span>, {props.county}</span>}
                        {props.zip && <span>, {props.zip}</span>}
                    </p>
                }

                <p className = "AddressCountry"> {props.country}</p>
            </div>
        </div>
    );
}

SiteCard.propTypes = {
    id: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    address4: PropTypes.string,
    city: PropTypes.string,
    county: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string,
    dispatch: PropTypes.any
}

export default SiteCard