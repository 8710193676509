import React, { Component } from 'react';
import { Grid, Modal, Icon } from 'semantic-ui-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ExtendedTextInput } from './';

export default class Comments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            commentModalIsOpen: false
        };
    }

    render() {
        let comments = [];
        let self = this;
        if(this.props.comments){
            this.props.comments.sort(function(a, b){
                let dateA = new Date(a.commentDate);
                let dateB = new Date(b.commentDate);
                return dateB - dateA;
            });

            this.props.comments.forEach(function(c){
                comments.push(
                    <div className="border-top" key={c._id}>
                        <div className="comment-header">
                            <div className="comment-header-left"><Icon name="edit" /> {c.userName}</div>
                            <div className="comment-header-right">{moment(c.commentDate).format(self.props.dateFormat)}</div>
                        </div>
                        <div className="comment-content">
                            {c.comment}
                        </div>
                    </div>);
            });
        }
        
        return(
            <div>
                <Grid padded="vertically" stackable>
                    <Grid.Row>
                        <Grid.Column width="3">
                            <h2>{this.props.title}</h2>
                        </Grid.Column>
                        {this.props.enabled? 
                            <Grid.Column className="actions" width={13}>
                                <button
                                    onClick={() => {
                                        this.setState({commentModalIsOpen: true});
                                    }}
                                    className="btn btn-outline btn-primary">
                                        Add comment <span className="glyphicon glyphicon-plus" />
                                </button>
                            </Grid.Column>
                            : null
                        }
                    </Grid.Row>
                </Grid>
                {comments}

                <Modal open={this.state.commentModalIsOpen} >
                    <Modal.Header>
                        <h4 className="modal-title">Add a comment</h4>
                    </Modal.Header>
                    <Modal.Content>
                        <ExtendedTextInput
                            value={this.state.newCommentText}
                            onChange={(event, {value}) =>
                                this.setState({ newCommentText: value })
                            }/>
                    </Modal.Content>
                    <Modal.Actions>
                        <button
                            type="button"
                            className="btn btn-danger"
                            data-dismiss="modal"
                            onClick={() => {
                                this.setState({commentModalIsOpen: false, newCommentText: ''});
                            }}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            id="addCommentButton"
                            className="btn btn-primary"
                            onClick={() =>{
                                this.props.addComment(this.state.newCommentText);
                                this.setState({commentModalIsOpen: false, newCommentText: ''});
                            }}>
                            Save
                        </button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}
Comments.defaultProps = {
    dateFormat: 'DD/MMM/YYYY HH:mm.ss',
    title: 'Comments'
};
Comments.propTypes = {
    enabled: PropTypes.bool,
    addComment: PropTypes.func,
    comments: PropTypes.array,
    dateFormat: PropTypes.string,
    title: PropTypes.string
};