import React, { Component } from 'react';
import { connect } from "react-redux";
import { SidebarComponent, Navbar, JobCard } from '../../_components';
import { getCompletedJobsForSiteAndYear } from '../../_actions';
import { jobConstants } from '../../_constants'
import { Spinner } from '../../_components/common/'
import { Button } from 'semantic-ui-react';

class Logbook extends Component {
    constructor(props){
        super(props);
        this.renderPage = this.renderPage.bind(this);
        this.getLastYear = this.getLastYear.bind(this);
        this.getNextYear = this.getNextYear.bind(this);
        this.getMonthClassName = this.getMonthClassName.bind(this);
        
        this.state = {
            selectedYear: (new Date()).getFullYear()
        }
    }
    
    getLastYear() {
        const newYear = this.state.selectedYear - 1;
        this.setState(
            { selectedYear : newYear }
        );
        this.props.getCompletedJobsForSiteAndYear(this.props.params.id, newYear);
    }
    
    getNextYear() {
        const newYear = this.state.selectedYear + 1;
        this.setState(
            { selectedYear : newYear }
        );
        this.props.getCompletedJobsForSiteAndYear(this.props.params.id, newYear);
    }

    getMonthClassName(monthNumber)
    {
        var month = new Date().getUTCMonth();
        if (month === monthNumber)
            return "top attached ui segment month text-center color";
        return "top attached ui segment month text-center";
    }

    componentWillMount() {
        this.props.getCompletedJobsForSiteAndYear(this.props.params.id, this.state.selectedYear);
    }

    redirect(url) {
        this.props.router.push(url);
    }

    render() {
        console.log('======render logbook======');
        return (
            <div>
                <Navbar handleClick={(event) => console.log(event.target)}/>
                <SidebarComponent content={this.renderPage()}/>
            </div>
        )
    }

    renderPage() {
        return (
            <div>
                <h1>Logbook - {this.props.params.name}</h1>
                <div className="actions">
                    <Button primary
                        onClick={() => this.redirect(`/documentlibrary/${this.props.params.id}`)}>
                        <Button.Content visible>
                            Document Library
                        </Button.Content>
                    </Button>
                </div>
                <div className="list-body">{this.renderLogbook()}</div>
            </div>
        )
    }

    renderLogbook() {
        return (
            <div className="ui vertical stripe segment">
                <div className="ui spacer">
                    <div className="ui grid twelve columns cards doubling stackable">
                        <div className="ui styled fluid accordion">
                            <h2 className="text-center">
                                <i className="ui icon caret left" onClick={this.getLastYear}/>
                                {this.state.selectedYear}
                                <i className="ui icon caret right" onClick={this.getNextYear}/>
                            </h2>
                        </div>

                        {jobConstants.Months.map(
                            (month, i) =>
                                <div key={i} className="column zero">
                                    <div className={this.getMonthClassName(i)}>{month}</div>
                                    {this.props.jobs ?
                                        <JobCard
                                            key={i}
                                            jobs={this.props.jobs[i]}/> :
                                        <div></div>}
                                </div>
                        )}
                    </div>
                    {this.props.jobs ?
                        <div></div> :
                        <Spinner caption="Jobs loading..."/>
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { component, oidc, job} = state;
    const { sidebarVisible } = component;
    const { user }  = oidc;
    const { jobs } = job;
    return {
        sidebarVisible,
        user,
        jobs
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        getCompletedJobsForSiteAndYear: (siteId, year) => dispatch(getCompletedJobsForSiteAndYear(siteId, year))
    };
}

const connectedLogbook = connect(mapStateToProps, mapDispatchToProps)(Logbook);
export { connectedLogbook as Logbook };