import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { ToolTip } from '.';

export class Category extends Component{
    constructor(props) {
        super(props);
        this.getClass = this.getClass.bind(this);
    }

    getClass(category){
        let className = "";
        if(category){
            category = category.toLowerCase();
            category = category.replace(/\s/g, '');

            if(this.props.availableCategories.indexOf(category) > -1){
                className = this.props.stylePrefix+category;
            }
        }
        return className;
    }

    render () {
        let content = 
        <div className={this.getClass(this.props.category)}>
            {this.props.showCategoryText? this.props.category: ""}
        </div>;
        return(
            <div>
                {this.props.categoryToolTip && this.props.categoryToolTip.length >0 ?
                    <ToolTip 
                        message={this.props.categoryToolTip} 
                        content={content}
                    />
                    : content
                }
            </div>
            
        );
    }
}
Category.defaultProps = {
    showCategoryText: false,
    stylePrefix: 'category-'
}
Category.propTypes = {
    stylePrefix: PropTypes.string,
    availableCategories: PropTypes.array,
    category: PropTypes.string,
    showCategoryText: PropTypes.bool,
    categoryToolTip: PropTypes.string
}