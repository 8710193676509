import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, Table } from 'semantic-ui-react';
import { appConstants } from '../../_constants';

export default class Paginator extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            backDisabled: true,
            forwardDisabled: true
        };

        this.getPageItems = this.getPageItems.bind(this);
        this.setPagerState = this.setPagerState.bind(this);
        this.paginate = this.paginate.bind(this);
        this.numberOfPages = this.numberOfPages.bind(this);
        this.atLastPage = this.atLastPage.bind(this);
        this.atFirstPage = this.atFirstPage.bind(this);
        this.renderSummary = this.renderSummary.bind(this);
        this.renderPager = this.renderPager.bind(this);
    }
    
    componentDidMount(){
        this.setPagerState(this.props.rowcount, this.props.page);
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.rowcount !== nextProps.rowcount)
            this.setPagerState(nextProps.rowcount, nextProps.page);
    }
    
    paginate(pageNum){
        this.setPagerState(this.props.rowcount, pageNum);
        this.props.paginate(pageNum);
    }

    setPagerState(rowCount, pageNum){
        this.setState({ 
            backDisabled: this.atFirstPage(pageNum, rowCount), 
            forwardDisabled: this.atLastPage(pageNum, rowCount),  
        });
    }

    numberOfPages(rowCount){
        return Math.ceil(rowCount / this.props.limit);
    }

    atLastPage(pageNum, rowCount){
        let numberOfPages = this.numberOfPages(rowCount);
        return (pageNum === numberOfPages - 1 || numberOfPages === 0);
    }

    atFirstPage(pageNum, rowCount){
        return (pageNum === 0 || this.numberOfPages(rowCount) === 0);
    }

    getPageItems(totalPages, currentPage) {
        var start, end;
        if (totalPages <= appConstants.PAGINATION_TABS_TOTAL)
        {
            start = 1;
            end = totalPages;
        } else {
            if (currentPage <= (appConstants.PAGINATION_TABS_FIRSTHALF + 1)) {
                start = 1;
                end = appConstants.PAGINATION_TABS_TOTAL;
            } else if (currentPage + appConstants.PAGINATION_TABS_LASTHALF >= totalPages) {
                start = totalPages - (appConstants.PAGINATION_TABS_TOTAL - 1);
                end = totalPages;
            } else {
                start = currentPage - appConstants.PAGINATION_TABS_FIRSTHALF;
                end = currentPage + appConstants.PAGINATION_TABS_LASTHALF;
            }
        }

        let menuItems = [];
        for(let i = start; i <= end; i++){
            let page = i-1;
            menuItems.push(
                <Menu.Item key={page} active={currentPage === page} onClick={() => this.paginate(page)} as="a">
                    {i}
                </Menu.Item>
            );
        }
        return menuItems;
    }

    renderSummary(){
        let visible = this.props.rowcount > 0;
        if(!visible){
            return React.Fragment;
        }

        let pageStart = this.props.limit * this.props.page + 1;
        let pageEnd = (this.props.page + 1) * this.props.limit;

        if(this.atLastPage(this.props.page, this.props.rowcount)){
            pageEnd = this.props.rowcount;
        }

        return (
            <div className="datatables-info">
                Showing {pageStart} to {pageEnd} of {this.props.rowcount} entries
            </div>
        );
    }

    renderPager(){
        const pageCount = this.numberOfPages(this.props.rowcount);

        if(pageCount <= 1){
            return React.Fragment;
        }
        
        return (
            <div className="ui centered grid">
                <Menu pagination>
                    <Menu.Item as="a" icon disabled={this.atFirstPage(this.props.page, this.props.rowcount)} onClick={() => this.paginate(0)}>
                        First
                    </Menu.Item>
                    <Menu.Item as="a" icon disabled={this.state.backDisabled} onClick={() => this.paginate(this.props.page - 1)}>
                        Previous
                    </Menu.Item>

                    {this.getPageItems(pageCount, this.props.page)}

                    <Menu.Item as="a" icon disabled={this.state.forwardDisabled} onClick={() => this.paginate(this.props.page + 1)}>
                        Next
                    </Menu.Item>
                    <Menu.Item as="a" icon disabled={this.atLastPage(this.props.page, this.props.rowcount)} onClick={() => this.paginate(pageCount-1)}>
                        Last
                    </Menu.Item>
                </Menu>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderSummary()}
                {this.renderPager()}
            </div>
        );
    }
}

Paginator.propTypes = {
    page: PropTypes.number,
    limit: PropTypes.number,
    paginate: PropTypes.func,
    rowcount: PropTypes.number
};
