import { createStore, applyMiddleware, compose } from "redux";
import { browserHistory } from "react-router";
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from "react-router-redux";
import { loadUser } from "redux-oidc";
import reducer from "../_reducers";
import userManager from "../utils/userManager";
import { loadState, saveState } from "./state/sessionStorage";
import throttle from 'lodash/throttle';

const loggerMiddleware = store => next => action => {
    next(action);
};

const initialState = loadState();

const createStoreWithMiddleware = compose(
    applyMiddleware(
        loggerMiddleware,
        thunkMiddleware,
        routerMiddleware(browserHistory)
    )
)(createStore);

const store = createStoreWithMiddleware(reducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
loadUser(store, userManager);

store.subscribe(throttle(() => {
    saveState({
        "auth" : {
            permissions: store.getState().auth.permissions,
            features: store.getState().auth.features
        }
    });
}, 1000));

export default store;