import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Icon, Menu, Segment, Sidebar } from 'semantic-ui-react'
import _ from 'lodash'
import userManager from '../utils/userManager'
import './components.css'
import CommonHelper from '../_helpers/CommonHelper';
import {permissionConstants} from '../_constants'

class SidebarComponent extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event, target) {
        const { user } = this.props;
        event.preventDefault();

        userManager.removeUser().then(function(){
            userManager.signoutRedirect({ 'id_token_hint': _.get(user, 'id_token')});
        });
    }

    render() {
        return (
            <div>
                {/*   inline style ensures sidebar is full height
                see https://github.com/Semantic-Org/Semantic-UI-React/issues/1408 */}
                <Sidebar.Pushable as={Segment} className="Sidebar-fullheight">
                    <Sidebar
                        as={Menu}
                        animation='push'
                        icon='labeled'
                        inverted
                        onHide={this.handleSidebarHide}
                        vertical
                        visible={this.props.sidebarVisible}
                    >
                        {/* <Menu.Item as='a' onClick={() => this.props.dispatch(push('/dashboard'))}>
                            <Icon name='home' />
                        </Menu.Item> */}
                        <Menu.Item as='a' onClick={() => this.props.dispatch(push('/sitelist'))}>
                            <Icon name='building' />
                        </Menu.Item>
                        {this.props.hasIssueViewPermission? 
                            <Menu.Item as='a' onClick={() => this.props.dispatch(push('/issueList'))}>
                                <Icon name='exclamation circle' />
                            </Menu.Item>
                            :null
                        }
                        <Menu.Item as='a' onClick={this.handleClick}>
                            <Icon name='log out' />
                        </Menu.Item>
                    </Sidebar>

                    <Sidebar.Pusher>
                        {this.props.content}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { component , oidc} = state;
    const { sidebarVisible } = component;
    const { user } = oidc;

    const viewPermission = CommonHelper.arrayContainsOneOrMoreStrings(state.auth.permissions, permissionConstants.GOD_MODE, permissionConstants.ISSUE.VIEW);

    return {
        sidebarVisible,
        user,
        hasIssueViewPermission: viewPermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarComponent);