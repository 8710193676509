import axios from 'axios';
import userManager from '../utils/userManager'
import {apiConfig } from '../_config'

/*
    Instance
*/
const http = axios.create({
    baseURL: apiConfig.baseApiUrl
});

console.log('baseUrl is', apiConfig.baseApiUrl);

http.defaults.headers.post['Content-Type'] = 'application/json';
http.defaults.headers.put['Content-Type'] = 'application/json';

http.interceptors.request.use(async config => {
    const user = await userManager.getUser();
    if (user && !user.expired)
        config.headers.Authorization = 'Bearer ' + user.access_token

    return config;
});

http.interceptors.response.use(function (response) {
    if (response.status !== 200) {
        console.log('response unexpected status:', response.status);
        console.log(response);
        handleUnauthorised(response);
    }
    return response;
    
}, function (error) {
    console.log(error);
    handleUnauthorised(error.response);
    return Promise.reject(error);
});

function handleUnauthorised(httpResponse){
    if(httpResponse && httpResponse.status){
        if(httpResponse.status === 401){
            window.location = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/unauthorised`
        }
    }
}

export default http;