import { jobConstants } from '../_constants';

const initialState = {
    response: {},
    error: ''
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case jobConstants.JOBS_GET_BY_SITE_AND_YEAR_REQUEST:
            return { ...state, response: null, jobs: null };
        case jobConstants.JOBS_GET_BY_SITE_AND_YEAR_SUCCESS:
            return { ...state, jobs: action.payload};
        case jobConstants.JOBS_GET_BY_SITE_AND_YEAR_FAILURE:
            return { ...state, error: action.payload};
        default:
            return state;
    }
}