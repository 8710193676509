import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { DropdownSearchable, TagLookup } from '../common';
import { issuesService, organisationService, locationTagService, assetService } from '../../services';

export class IssueLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            childOrgDisabled: true,
            assetDisabled: true,
            locationDisabled: true,
            clientDisabled: true,
            sites: [],
            selectedClient: null,
            selectedSite: null,
            selectedLocation: null,
            selectedAsset: null
        };
        this.searchParentOrgs = this.searchParentOrgs.bind(this);
        this.searchChildOrgs = this.searchChildOrgs.bind(this);
        this.populateLocationsForChildOrg = this.populateLocationsForChildOrg.bind(this);
        this.populateAssetsForChildOrg = this.populateAssetsForChildOrg.bind(this);
        this.setParentOrg = this.setParentOrg.bind(this);
        this.setChildOrg = this.setChildOrg.bind(this);
        this.setAsset = this.setAsset.bind(this);
        this.loadNextParentOrgs = this.loadNextParentOrgs.bind(this);
        this.loadNextChildOrgs = this.loadNextChildOrgs.bind(this);
        this.handleParentResponse = this.handleParentResponse.bind(this);

        this.orgPromise = null;
        this.sitePromise = null;
    }

    searchParentOrgs(searchText, skip, take = this.props.itemsInSelect, append = false) {
        if (this.orgPromise !== null) {
            this.orgPromise.cancel('Search Client Cancelled');
        }
        if (_.isNil(searchText)) {
            searchText = '';
        }

        if (!searchText) {
            this.orgPromise = organisationService.getImmediateParentsOfSites(take, skip);
        } else {
            this.orgPromise = organisationService.getImmediateParentsOfSitesBySearch(searchText, take, skip);
        }

        this.orgPromise
            .then((response) => {
                this.handleParentResponse(response, searchText, take, append);
            });
    }

    handleParentResponse(response, searchText, take, append) {
        this.orgPromise = null;
        if (!response || !response.data) {
            return;
        }
        this.canGetMoreParents = !(response.data.length < take);
        const parentOrgs = append ? this.state.parentOrgs : [];
        issuesService.convertToReactUsable(parentOrgs, response.data);
        this.setState({
            parentOrgs,
            childOrgDisabled: true,
            locationDisabled: true,
            assetDisabled: true,
            orgSearchText: searchText
        });
    }

    searchChildOrgs(searchText, skip, take = this.props.itemsInSelect, append = false) {
        if (this.sitePromise !== null) {
            this.sitePromise.cancel('Search Site Cancelled');
        }

        if (_.isNil(searchText)) searchText = '';

        this.sitePromise = organisationService.getSitesForOrganisationBySearchLimited(this.state.selectedClient, searchText, take, skip);
        this.sitePromise.then((response) => {
            this.sitePromise = null;
            if(!response || !response.data){
                return;
            }
            this.canGetMoreChildren = !(response.data.length < take);
            const childOrgs = append ? this.state.childOrgs : [];
            issuesService.convertToReactUsable(childOrgs, response.data);
            this.setState({
                childOrgs,
                childSearchText: searchText,
                childOrgDisabled: childOrgs.length === 0,
                locationDisabled: true,
                assetDisabled: true
            });
        });
    }

    populateLocationsForChildOrg(id) {
        this.setState({
            childOrgId: id,
            assetDisabled: false,
            locationDisabled: false,
            childOrgDisabled: false
        });
    }

    populateAssetsForChildOrg(siteId) {
        let self = this;
        assetService.getAssetsOnSite(siteId).then(function(response) {
            const assets = [];
            issuesService.convertToReactUsable(assets, response.data, true);
            self.setState({
                assets,
                assetData: response.data,
                assetDisabled: false,
                locationDisabled: false,
                childOrgDisabled: false
            });
        });
    }

    setParentOrg(e, { value, options }) {
        const ddlEntry = _.find(options, o => o.value === value);
        this.setState({ assets: [], selectedAsset: null, selectedLocation: null, selectedSite: null, selectedClient: value}, () => {
            this.props.parentOrgSelected(ddlEntry.text, ddlEntry.value);
            this.searchChildOrgs();
        });
    }

    setChildOrg(e, { value, options }) {
        const ddlEntry = _.find(options, o => o.value === value);
        this.setState({assets: [], selectedAsset: null, selectedLocation: null, selectedSite: value});

        this.props.childOrgSelected(ddlEntry.text, ddlEntry.value);
        this.populateLocationsForChildOrg(value);
        this.populateAssetsForChildOrg(value);
    }

    setLocation(location) {
        this.props.locationSelected(location.tag, location._id);
        this.setState({selectedAsset: null, selectedLocation: location});
        this.getAssetsOnLocation(location._id);
    }

    setAsset(e, { value, options }) {
        const asset = _.find(this.state.assetData, a => a.id === value);
        this.setState({selectedAsset: value});
        this.props.entitySelected(asset);
    }

    getAssetsOnLocation(location) {
        let self = this;
        assetService.getAssetsOnLocation(location).then(function(result) {
            let assets = [];
            issuesService.convertToReactUsable(assets, result.data, true);
            self.setState({
                assets,
                assetData: result.data,
                assetDisabled: false,
                locationDisabled: false,
                childOrgDisabled: false
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.rootstate) return;
        let locationObj = nextProps.rootstate.locationOjbect;
        if (locationObj) {
            if (
                this.props.rootstate.locationOjbect &&
                this.props.rootstate.locationOjbect._id === locationObj._id
            ) {
                return;
            }
            this.getAssetsOnLocation(nextProps.rootstate.locationOjbect.tag);
        }

        if(nextProps.currentLocation !== this.state.selectedLocation){
            this.setState({selectedLocation: nextProps.currentLocation});
        }
    }

    loadNextParentOrgs(event) {
        const { target } = event;
        if (this.canGetMoreParents && shouldLoadMore(target, this.orgPromise)) {
            this.searchParentOrgs(this.state.orgSearchText, this.state.parentOrgs.length, this.props.itemsInSelect, true);
        }
    }

    loadNextChildOrgs(event) {
        const { target } = event;
        if (this.canGetMoreChildren && shouldLoadMore(target, this.sitePromise)) {
            this.searchChildOrgs(this.state.childSearchText, this.state.childOrgs.length, this.props.itemsInSelect, true);
        }
    }

    render() {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        {!this.props.editMode?
                            <h3> {this.props.locationPlaceholderParent}</h3>
                            :
                            <DropdownSearchable
                                title="Client"
                                placeholder={this.props.locationPlaceholderParent}
                                disabled={this.props.disableAll}
                                required={true}
                                options={this.state.parentOrgs}
                                searchable={true}
                                onChange={this.setParentOrg}
                                onSearchChange={this.searchParentOrgs}
                                value={this.state.selectedClient}
                                onScroll={this.loadNextParentOrgs}
                                onClick={(e, { searchQuery }) => { this.searchParentOrgs(searchQuery); }}
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {!this.props.editMode?
                            <p><Icon name="building" /> {this.props.locationPlaceholderChild}</p>
                            :
                            <DropdownSearchable
                                title="Site"
                                placeholder={this.props.locationPlaceholderChild}
                                disabled={this.state.childOrgDisabled || this.props.disableAll}
                                options={this.state.childOrgs}
                                searchable={true}
                                onChange={this.setChildOrg}
                                onSearchChange={this.searchChildOrgs}
                                value={this.state.selectedSite}
                                onScroll={this.loadNextChildOrgs}
                                onClick={(e, { searchQuery }) => { this.searchChildOrgs(searchQuery); }}
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {!this.props.editMode?
                            <p><Icon name="map marker" /> {this.props.locationPlaceholderLocation}</p>
                            :
                            <TagLookup
                                caption="Location"
                                currentvalue={this.state.selectedLocation}
                                disabled={this.state.locationDisabled || this.props.disableAll}
                                parentid={this.state.childOrgId}
                                tagchangedcallback={data =>
                                    this.setLocation(data)
                                }
                                listPlaceholder={this.props.locationPlaceholderLocation}
                                tagsService={locationTagService}
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {!this.props.editMode?
                            <p><Icon name="box" />{this.props.locationPlaceholderAsset}</p>
                            :
                            <DropdownSearchable
                                title="Asset"
                                placeholder={this.props.locationPlaceholderAsset}
                                disabled={this.state.assetDisabled || this.props.disableAll}
                                options={this.state.assets}
                                searchable={false}
                                onChange={this.setAsset}
                                value={this.state.selectedAsset}
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

function shouldLoadMore(target, promise) {
    var remainingHeight = target.offsetHeight - target.scrollHeight;
    var scrollTop = target.scrollTop;
    var percent = Math.abs((scrollTop / remainingHeight) * 100);

    return (percent >= 80 && promise === null);
}

IssueLocation.propTypes = {
    onChange: PropTypes.func,
    parents: PropTypes.object,
    rootstate: PropTypes.any,
    setLocationUsingName: PropTypes.func,
    itemsInSelect: PropTypes.number,

    parentOrgSelected: PropTypes.func,
    childOrgSelected: PropTypes.func,
    locationSelected: PropTypes.func,
    entitySelected: PropTypes.func,

    currentLocation: PropTypes.object,
    disableAll: PropTypes.bool,
    editMode: PropTypes.bool,

    locationPlaceholderParent: PropTypes.string,
    locationPlaceholderChild: PropTypes.string,
    locationPlaceholderLocation: PropTypes.string,
    locationPlaceholderAsset: PropTypes.string
};
