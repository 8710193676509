import http from '../utils/api'
import _ from 'lodash';
import CommonHelper from '../_helpers/CommonHelper';

const issueApi = 'issues';

const prepareRequestObject = function(obj) {
    const s1 = JSON.stringify(obj);
    return JSON.stringify(s1);
};

const convertToReactUsable = function(target, data, addempty) {
    if(addempty === true){
        target.push({ key: 0, text: '<none>', value: null })
    }

    // keyFactor required when appending to existing data
    // e.g. scrolldown, fetch more, need to get data into this format
    var initialTargetLength = target.length;
    var keyFactor = addempty ? initialTargetLength + 1 : initialTargetLength;


    for (var i = 0; i < data.length; i++) {
        target.push({
            key: keyFactor + i,
            text: data[i].name || data[i].tag,
            value: data[i].id || data[i]._id
        });
    }
    return target;
};

const issueSearchPaged= (from, to, searchTerm, limit, page, sortFields, sortAsc, jobId) => {
    let fromQs = from? `from=${from}&`: '';
    let query = `?${fromQs}to=${to}&term=${searchTerm}&page=${page}&limit=${limit}`;

    for(let x = 0; x< sortFields.length; x++){
        query = `${query}&sort[${x}][field]=${sortFields[x]}&sort[${x}][isAcending]=${sortAsc}`;
    }

    if(jobId){
        query = `${query}&parent[0][id]=${jobId}`;
    }
    
    return http.get(`${issueApi}${query}`)
        .then(response => {
            return response.data;
        });
}

const getIssueById = function(id) {
    return http.get(issueApi + '/' + id);
};

const createIssue = function(issue) {
    let parents = Object.values(issue.parents);
    parents = parents.concat(issue.ancestors);

    const parentHeaderString = CommonHelper.arrayToBase64(parents);
    delete issue.parents;
    const s1 = JSON.stringify(issue);
    const s2 = JSON.stringify(s1);

    return http({
        method: 'POST',
        url: issueApi,
        headers: { parents: parentHeaderString },
        data: s2
    });
};

const updateIssue = function(issue) {

    const id = _.get(issue, 'id');
    const issueChanges = {
        description: _.get(issue, 'description'),
        priority: _.get(issue, 'priority'),
        state: _.get(issue, 'state'),
    };

    const body = this.prepareRequestObject(issueChanges);
    const req = {
        method: 'PUT',
        url: issueApi + '/' + id,
        data: body
    };
    
    return http(req);
};

const deleteIssue = function(id) {
    return http.delete(issueApi + '/' + id);
};

const addComment = function(id, comment) {
    const s1 = JSON.stringify(comment);
    const s2 = JSON.stringify(s1);

    const req = {
        method: 'POST',
        url: issueApi + '/' + id + '/comment',
        data: s2
    };
    return http(req);
};

export const issuesService = {
    prepareRequestObject,
    convertToReactUsable,
    issueSearchPaged,
    getIssueById,
    createIssue,
    updateIssue,
    deleteIssue,
    addComment
}