import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/TextInput.jsx';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Icon } from 'semantic-ui-react';

export default class Search extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    // data is the local time
    handleChange(event, data, label) {
        if(label === 'keyPress' && event.key === 'Enter'){
            this.props.performSearch(true);
        }
        let myUtcDate = moment.utc(data).format();
        label === 'search'
            ? this.props.onChange(event, data, label)
            : this.props.onChange(event, myUtcDate, label);

        if (label === 'startdate'){
            this.setState({
                startUtcDate: myUtcDate
            });
        } else if (label === 'enddate'){
            this.setState({
                endUtcDate: myUtcDate
            });
        }
    }

    getLocaleDate(date) {
        if(date){
            return moment(date).locale(this.props.locale);
        }
        return null;
    }

    render() {
        return (
            <div className="IssueSearch">
                <div className="ui grid stackable">
                    <div className="five wide column"> 
                        <TextInput
                            className="full-width"
                            placeholder={"Search: "+ this.props.placeholderText}
                            onChange={(event, data) =>
                                this.handleChange(event, data, 'search')
                            }
                            onKeyPress={(event) =>
                                this.handleChange(event, null, 'keyPress')
                            }
                        />
                    </div>
                    {this.props.showDateFilters ?
                        <div className="four wide column">
                            <div className="ui grid stackable">
                                <div className="seven wide column">
                                    <DatePicker
                                        className="date-picker full-width"
                                        selected={this.getLocaleDate(this.props.startUtcDate)}
                                        onChange={data =>
                                            this.handleChange(data, data, 'startdate')
                                        }
                                        dateFormat={this.props.dateFormatNoTime}
                                    />
                                </div>
                                <div className="two wide column">
                                    <p className="search-date-delimiter">&mdash;</p>
                                </div>
                                <div className="seven wide column">
                                    <DatePicker
                                        className="date-picker full-width"
                                        selected={this.getLocaleDate(this.props.endUtcDate)}
                                        onChange={data =>
                                            this.handleChange(data, data, 'enddate')
                                        }
                                        dateFormat={this.props.dateFormatNoTime}
                                    />
                                </div>
                            </div>
                        </div>
                        :null
                    }
                    
                    <div className="two wide column">
                        <button
                            className="btn btn-outline btn-primary btn-block"
                            onClick={() => this.props.performSearch(true)}>
                            <Icon name="search"/> Search 
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

Search.defaultProps = {
    showDateFilters: false
};

Search.propTypes = {
    onChange: PropTypes.func,
    performSearch: PropTypes.func,
    startUtcDate: PropTypes.string,
    endUtcDate: PropTypes.string,
    locale: PropTypes.string,
    dateFormatNoTime: PropTypes.string,
    placeholderText: PropTypes.string,
    showDateFilters: PropTypes.bool
};
