import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

export default class SemList extends React.Component {
    render() {
        if (!this.props.items) return <div />;
        return (
            <List divided relaxed>
                {this.props.items.map((item, key) => {
                    return (
                        <List.Item key={key}>
                            <List.Content floated="right">{item.action}</List.Content>
                            <List.Icon name="clipboard" size="large" verticalAlign="middle" />
                            <List.Content>
                                <List.Header as="label">{item.ref}</List.Header>
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
        );
    }
}

SemList.propTypes = {
    items: PropTypes.any
};
