import React, { Component } from 'react'

import { Navbar, SidebarComponent } from '../../_components'

export class ForbiddenPage extends Component {
    render() {
        return (
            <div>
                <Navbar handleClick={(event) => console.log(event.target)}/>
                <SidebarComponent content={this.renderPage()}/>
            </div>
        )
    }

    renderPage() {
        return (
            <div>
                <h1>403 - Forbidden</h1><br/>
                <h2>Your subscription does not have the required permission. Please contact your administrator if you would like to upgrade.</h2>
            </div>
        )
    }
}
