import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react'

export default class ToolTip extends React.Component {
    render() {
        let show = this.props.message && this.props.message.length > 0 && this.props.content;

        return !show ? null : (
            <Popup trigger={this.props.content} content={this.props.message} position={this.props.position}>
            </Popup>
        );
    }
}

ToolTip.defaultProps = {
    position: 'top center'
};

ToolTip.propTypes = {
    message: PropTypes.string,
    content: PropTypes.any,
    position: PropTypes.string
};
