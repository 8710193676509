import { appConstants } from '../_constants';

const initialState = {
    sidebarVisible: true
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case appConstants.TOGGLE_SIDEBAR_VISIBLE:
            return { ...state, sidebarVisible: action.payload };
        default:
            return state;
    }
}
