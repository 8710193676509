import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Icon, Modal } from 'semantic-ui-react';

import { SemTable, Search, Category } from '../../_components/common';
import { SidebarComponent, Navbar } from '../../_components';
import { issuesService } from '../../services'
import CommonHelper from '../../_helpers/CommonHelper';
import {permissionConstants, featureConstants} from '../../_constants'

class IssuesList extends Component {
    constructor(props) {
        super(props);

        this.ascendingDefault = false;

        this.locale = CommonHelper.getLocate(this.props.user);
        this.dateformat = CommonHelper.getLocalDateFormat(this.props.user, true);
        this.dateformatnotime = CommonHelper.getLocalDateFormat(this.props.user);

        this.handleChange = this.handleChange.bind(this);
        this.performSearch = this.performSearch.bind(this);
        this.paginate = this.paginate.bind(this);
        this.getRows = this.getRows.bind(this);
        this.sortCol = this.sortCol.bind(this);
        this.gotInit = false;

        this.state = {
            search: '',
            limit: 10,
            page: 0,
            headings: [
                'Priority',
                'Reference',
                'Description',
                'Issue Type',
                'Site',
                'Location',
                'Asset',
                'Measurement',
                'Created',
                'Status'
            ],
            ascending: this.ascendingDefault,
            sortfields: ['issueRef'],
            clickedcolumn: 'Reference',
            delModalOpen: false,
            startdate: null,
            enddate: moment()
                .utc()
                .format()
        };

        this.actions = (id) => {
            var edit = (
                <button
                    className="btn btn-outline btn-primary action-btn center aligned"
                    onClick={() => this.props.dispatch(push('/issueEdit/'+id))}>
                    <Icon name="edit" className="center"/>
                </button>
            );
            var del = (
                <button
                    className="btn btn-outline btn-danger action-btn"
                    onClick={() => this.showDeleteModal(id)}>
                    <Icon name="delete" />
                </button>
            );

            return (
                <div>
                    {this.props.hasEditPermission === true && edit}
                    {this.props.hasDeletePermission === true && del}
                </div>
            );
        };
    }

    // date is the utc time
    handleChange(event, data, label) {
        if (data) {
            this.setState({
                [label]: data.value
            });
        } else {
            this.setState({
                [label]: null
            });
        }

        if(label === 'startdate'){
            this.setState({ startdate: data });
        } else if(label === 'enddate'){
            this.setState({ enddate: data })
        }
    }

    performSearch() {
        let self = this;
        issuesService
            .issueSearchPaged(
                self.state.startdate,
                self.state.enddate,
                self.state.search,
                self.state.limit,
                self.state.page,
                self.state.sortfields,
                self.state.ascending,
                self.props.location.query.jobid
            )
            .then(function(response) {
                const parsed = JSON.parse(response);
                self.setState({ totalItemCount: parsed.count });
                self.getRows(parsed.data);
            });
    }

    paginate(newpage) {
        this.setState({ page: newpage }, () => this.performSearch());
    }

    getRows(data) {
        const self = this;
        let urlQueryString = self.props.location.query.jobid == undefined ? '' : '?jobid='+self.props.location.query.jobid;
        let rows = [];
        _.forEach(data, function(issue) {
            let formattedDate = CommonHelper.localisedDate(self.props.user, _.get(issue, 'systemHeader_createDetails.date'));
            let row = [];

            let entityNumber = issue.auxFields.entityNumber;
            let entityNameNumber = issue.auxFields.entityName;

            if(entityNumber && entityNumber !== ''){
                entityNameNumber = entityNumber + ' - '+entityNameNumber;
            }

            row.push(
                <Category 
                    category={_.get(issue,'priority.priority', '')} 
                    availableCategories={["high", "medium", "low"]}
                    categoryToolTip={_.get(issue,'priority.priority', '')} 
                />);
            row.push(<button
                className="link-button center aligned"
                onClick={() => self.props.dispatch(push('/issueEdit/'+issue.id+urlQueryString))}>
                {_.get(issue, 'issueRef', '')}
            </button>);
            row.push(_.get(issue, 'description', ''));
            row.push(_.get(issue, 'issueType.issueTypeName', ''));
            row.push(_.get(issue, 'auxFields.childOrgName', ''));
            row.push(_.get(issue, 'auxFields.location', ''));
            row.push(entityNameNumber || '');
            row.push(_.get(issue, 'auxFields.recordedValue', ''));
            row.push(formattedDate);
            row.push(
                <Category 
                    showCategoryText
                    category={self.changeState(_.get(issue, 'state'))} 
                    availableCategories={["open", "closed", "onhold"]} 
                    stylePrefix="category "
                />);
            rows.push(row);
        });
        this.setState({ rows: rows });
    }

    /**
     * This is temporary solution to avoid puting business logic into API gateway.
     * @param state enum value as state
     * @returns converted state name
     */
    changeState(state) {
        if (!state) {
            return state;
        }
        switch (state) {
            case 'OnHold':
                return 'On Hold';
            default:
                return state;
        }
    }
    
    sortCol(heading) {
        let sorts = [];
        const isAscending = heading === this.state.clickedcolumn ? !this.state.ascending : this.ascendingDefault;

        switch (heading.toLowerCase()) {
            case 'status':
                sorts.push('state');
                break;
            case 'reference':
                sorts.push('issueRef');
                break;
            case 'description':
                sorts.push('description');
                break;
            case 'issue type':
                sorts.push('issueType.issueTypeName');
                break;
            case 'site':
                sorts.push('auxFields.childOrgName');
                break;
            case 'location':
                sorts.push('auxFields.location');
                break;
            case 'asset':
                sorts.push('auxFields.entityNumber', 'auxFields.entityName');
                break;
            case 'measurement':
                sorts.push('auxFields.recordedValue');
                break;
            case 'created':
                sorts.push('systemHeader_createDetails.date');
                break;
            case 'priority':
                sorts.push('priority.priority');
                break;
            default:
                break;
        }

        this.setState({ ascending: isAscending, clickedcolumn: heading, sortfields: sorts}, () => this.paginate(0));
    }

    componentDidMount(){
        if(this.props.hasIssueFeature === false){
            this.props.dispatch(push('/forbidden'));
            return;
        }

        this.performSearch();
    }
    
    componentDidUpdate(prevProps){
        //if the querystring has been cleared, run another search without the jobid
        if(prevProps.location.query.jobid !== this.props.location.query.jobid){
            this.performSearch();
        }
    }

    showDeleteModal(id) {
        this.setState({ delModalOpen: true, idToDel: id });
    }

    delete() {
        const self = this;
        issuesService.deleteIssue(this.state.idToDel).then(function() {
            self.performSearch();
            self.setState({ delModalOpen: false });
        });
    }
    render() {
        return (
            <div>
                <Navbar handleClick={(event) => console.log(event.target)}/>
                <SidebarComponent content={this.renderPage()}/>
            </div>
        );
    }

    renderPage() {
        return (
            <div>
                <h1>Issue List</h1>

                <div className="actions">
                    {this.props.hasCreatePermission && (
                        <button
                            onClick={() => this.props.dispatch(push('/issueCreate'))}
                            className="btn btn-outline btn-primary btn-block">
                            <Icon name="add" /> Create Issue 
                        </button>
                    )}
                </div>
                <div className="list-body">
                    {this.props.location.query.jobid ?
                        <h4>Displaying issues for job &nbsp;
                            <button onClick={() =>  this.props.dispatch(push('/issueList'))}>
                                <Icon name="cancel" />
                            </button>
                        </h4>
                        : ''
                    }
                    <Search
                        onChange={this.handleChange}
                        performSearch={this.performSearch}
                        placeholderText={'Priority, Reference, Description, Issue Type, Site, Location, Aset, Asset Number, Measurement, Status'}
                        startUtcDate={this.state.startdate}
                        endUtcDate={this.state.enddate}
                        locale={this.locale}
                        dateFormatNoTime={this.dateformatnotime}
                        showDateFilters={true}
                    />

                    <SemTable
                        pagination
                        headings={this.state.headings}
                        rows={this.state.rows}
                        paginate={this.paginate}
                        sortfunc={this.sortCol}
                        rowcount={this.state.totalItemCount}
                        limit={this.state.limit}
                        page={this.state.page}
                        clickedcolumn={this.state.clickedcolumn}
                        direction={this.state.ascending ? 'ascending' : 'descending'}
                    />
                </div>

                <Modal
                    open={this.state.delModalOpen}
                    size='small'>
                    <Modal.Header>
                        Delete Issue
                    </Modal.Header>
                    <Modal.Content className="gray-bg">
                        <p>Are you sure you wish to delete this issue?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <button
                            onClick={() => this.setState({ delModalOpen: false })}
                            className="btn btn-default">
                            Cancel
                        </button>
                        <button onClick={() => this.delete()} className="btn btn-outline btn-primary">
                            OK
                        </button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { component, oidc, auth } = state;
    const { sidebarVisible } = component;
    const { user }  = oidc;
    
    const createPermission = CommonHelper.arrayContainsOneOrMoreStrings(auth.permissions, permissionConstants.GOD_MODE, permissionConstants.ISSUE.CREATE);
    const deletePermission = CommonHelper.arrayContainsOneOrMoreStrings(auth.permissions, permissionConstants.GOD_MODE, permissionConstants.ISSUE.DELETE);
    const issueFeature = CommonHelper.arrayContainsOneOrMoreStrings(auth.features, featureConstants.ISSUES);

    return {
        sidebarVisible,
        user,
        hasEditPermission: createPermission,
        hasDeletePermission: deletePermission,
        hasCreatePermission: createPermission,
        hasIssueFeature: issueFeature
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}
const connectedIssuesList = connect(mapStateToProps, mapDispatchToProps)(IssuesList);
export { connectedIssuesList as IssuesList };
