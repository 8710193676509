import { createUserManager } from 'redux-oidc';
import { apiConfig } from '../_config'

const userManagerConfig = {
    client_id: 'pulsionmobile.portalweb',
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    response_type: 'token id_token',
    scope: 'openid pulsionmobileapiaccess pulsionmobileprofile',
    authority: apiConfig.baseUrl + '/identity',
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
    automaticSilentRenew: true,
    monitorSession: false,
    filterProtocolClaims: false,
    loadUserInfo: true,
    revokeAccessTokenOnSignout: true,
    post_logout_redirect_uri:`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/loggedout`,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
