import React from 'react';
import PropTypes from 'prop-types'
import { ToastContainer, toast } from 'react-toastify'
import { appConstants } from '../_constants';

export default class PulsionToast extends React.Component {

    render() {
        return(
            <div className="">
                <ToastContainer
                    autoClose={appConstants.TOAST_DEFAULT_DISPLAY_TIME}
                    position={toast.POSITION.TOP_RIGHT}
                    hideProgressBar={this.props.hideProgressBar}
                />
            </div>
        );
    }
}

PulsionToast.defaultProps = {
    hideProgressBar: true
}

PulsionToast.propTypes = {
    hideProgressBar: PropTypes.bool
};
