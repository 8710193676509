import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class Panel extends Component {
    render() {
        return (
            <div className={`col-md-${this.props.divWidth}`}>
                <div className="grey-bg ibox">
                    {(this.props.visibility) ? 
                        <div className="ibox-content">
                            <div className="form-group">
                                <h2>{this.props.title}</h2>
                                {(!_.isNull(this.props.titleActions)) ? 
                                    <div className="actions">{this.props.titleActions}</div> :
                                    null
                                }
                                {this.props.content}
                            </div>
                        </div> :
                        <div></div>
                    }
                </div>
            </div>
        );
    }
}

Panel.defaultProps = {
    titleActions: null,
    visibility: true
};

Panel.propTypes = {
    divWidth: PropTypes.number,
    title: PropTypes.string,
    content: PropTypes.any,
    titleActions: PropTypes.any,
    visibility: PropTypes.bool
};
