import { SESSION_TERMINATED, USER_EXPIRED } from "redux-oidc";
import { authConstants } from '../_constants';

const initialState = {
    tenant: '',
    permissions: null,
    features: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SESSION_TERMINATED:
        case USER_EXPIRED:
            return { ...state };
    	case authConstants.SET_TENANT_QUERY:
            return { ...state, tenant: action.payload };
        case authConstants.SET_PERMISSIONS:
            return { ...state, permissions: action.payload};
        case authConstants.SET_FEATURES:
            return { ...state, features: action.payload};
        case authConstants.CLEAR_FEATURES_AND_PERMISSIONS:
            return { ...state, permissions: null, features: null }; 
        default:
            return state;
    }
}
