import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { OidcProvider } from 'redux-oidc'
import Routes from '../routes'
import store from '../_helpers/store'
import userManager from '../utils/userManager'
import 'semantic-ui-css/semantic.min.css'

import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/identityServer.css'

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <OidcProvider store={store} userManager={userManager}>
                    <Routes />
                </OidcProvider>
            </Provider>
        );
    }
}

export default App;
