import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

export default class CheckboxComp extends React.Component {
    render() {
        return (
            <div className="form-group">
                <Checkbox
                    onChange={this.props.onChange}
                    onClick={this.props.onClick}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    defaultChecked={this.props.default}
                    label={this.props.label}
                />
            </div>
        );
    }
}

CheckboxComp.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    default: PropTypes.bool
};
