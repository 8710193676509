import React from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import { push } from "react-router-redux";
import userManager from "../utils/userManager";
import { setFeatures, setPermissions, clearFeaturesAndPermissions } from '../_actions';
import { permissionService, tenantFeatureService } from '../services'

class CallbackPage extends React.Component {
    constructor(props){
        super(props);
        this.redirectSuccess = this.redirectSuccess.bind(this);
    }

    redirectSuccess(){
        const self = this;
        self.props.dispatch(clearFeaturesAndPermissions());

        permissionService.getPermissionsForCurrentUser()
            .then(data =>{
                self.props.dispatch(setPermissions(data));
                return tenantFeatureService.getTenantFeatures();
            })
            .then(data =>{
                self.props.dispatch(setFeatures(data.map(d => d.featureName)));
                self.props.dispatch(push("/"));
            })
            .catch((error) => {
                self.props.dispatch(clearFeaturesAndPermissions());
                self.props.dispatch(push("/"));
                console.error(error);
            });
    }

    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={ () =>{
                    this.redirectSuccess();
                }}
                errorCallback={error => {
                    this.props.dispatch(push("/"));
                    console.error(error);
                }}>
                <div>Redirecting...</div>
            </CallbackComponent>
        );
    }
}

export default connect()(CallbackPage);
