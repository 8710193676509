import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Grid, Icon, List } from 'semantic-ui-react'
import { jobService } from '../services'
import { PdfViewer } from '.'
import { SiteVisitResults } from '.'
import { organisationDocumentsService } from '../services'
import { Spinner } from './common';

export default class DocumentList extends React.Component {
    constructor(props){
        super(props);

        this.fetchDocumentList = this.fetchDocumentList.bind(this);
        this.fetchPdf = this.fetchPdf.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.downLoadPdf = this.downLoadPdf.bind(this);
        this.downloadDocument = this.downloadDocument.bind(this);
        this.getDocumentListItems = this.getDocumentListItems.bind(this);
        this.getDocumentListItem = this.getDocumentListItem.bind(this);
        this.getSiteVisitListItem = this.getSiteVisitListItem.bind(this);
        this.getDocumentCount = this.getDocumentCount.bind(this);
        this.getDocumentContent = this.getDocumentContent.bind(this);

        this.state = {
            documentList: null,
            pdfDocument: null,
            organisationDocumentPdf: null,
            organisationDocumentPdfFileName: null,
            document: null,
            firstLoad: true,
            selectedDoc: null
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.show !== this.props.show && this.props.show === true){
            this.fetchDocumentList();
        }
    }

    handleClose(event){
        this.setState({
            documentList: null,
            pdfDocument: null,
            document: null,
            firstLoad: true,
            selectedDoc: null,
            organisationDocumentPdf: null,
            organisationDocumentPdfFileName: null,
            showOrganisationDocument: false
        });

        this.props.closeModal(event, this.props.jobId);
    }

    fetchDocumentList(){
        const self = this;

        if (this.props.showOrganisationDocument) {
            organisationDocumentsService.getDocumentFile(this.props.organisationDocumentId, true, true)
                .then((result) => {
                    this.setState({
                        organisationDocumentPdf: result.data,
                        organisationDocumentPdfFileName: result.data.fileName,
                        firstLoad: false
                    });
                });
        } else {
            jobService.GetDocListForClient(this.props.jobId)
                .then(function(result){
                    self.setState(({documentList: result.data}), function(){
                        if(self.state.firstLoad === true){
                            if(self.props.isSiteVisit){
                                self.setState({siteVisit:true, firstLoad:false});
                            }else{
                                //render the first doc
                                if (self.state.documentList.generatedDocList && self.state.documentList.generatedDocList.length > 0){
                                    let firstDoc = self.state.documentList.generatedDocList[0];
                                    self.fetchPdf(firstDoc.documentId);
                                }
                                if (self.state.documentList.uploadedDocList && self.state.documentList.uploadedDocList.length > 0){
                                    let firstDoc = self.state.documentList.uploadedDocList[0];
                                    self.fetchUploadedDoc(firstDoc);
                                }
                            }
                        }
                    });
                });
        }
    }

    fetchPdf(documentId){
        const self = this;
        jobService.getPdfBase64(documentId)
            .then(function(result){
                self.setState({
                    pdfDocument: result.data,
                    firstLoad: false,
                    selectedDoc: documentId
                });
            });
    }

    fetchUploadedDoc(document){
        if (document.documentBase64){
            this.setState({
                pdfDocument: document,
                firstLoad: false,
                selectedDoc: document.documentId,
                siteVisit: false
            });
        }
        else {
            this.setState({
                pdfDocument: null,
                document: document,
                firstLoad: false,
                selectedDoc: document.documentId,
                siteVisit: false
            });
        }
    }

    downLoadPdf() {
        if(!this.state.pdfDocument){
            return;
        }
        this.refs.download.click();
    }

    downloadDocument() {
        if(!this.state.document){
            return;
        }
        window.location.href = this.state.document.documentUri;
    }

    downloadOrganisationDocument() {
        window.location.href = this.state.organisationDocumentPdf.documentUri;
    }

    getDocumentListItems(){
        if(this.noDocuments()){
            return null;
        }

        let listItems = [];
        if(this.props.isSiteVisit){
            listItems.push(this.getSiteVisitListItem());
        }

        if(this.state.documentList){
            if (this.state.documentList.generatedDocList){
                this.state.documentList.generatedDocList.forEach((doc) => {
                    listItems.push(this.getDocumentListItem(doc, false));
                });
            }

            if (this.state.documentList.uploadedDocList){
                this.state.documentList.uploadedDocList.forEach((doc)=> {
                    listItems.push(this.getDocumentListItem(doc, true));
                });
            }
        }

        if (this.state.organisationDocumentPdf) {
            listItems.push(this.getDocumentListItem(this.state.organisationDocumentPdf, true));
        }

        return listItems;
    }

    getDocumentContent(){
        if(this.noDocuments()){
            return <p className="text-center">No content</p>;
        }

        if(this.state.siteVisit){
            return <SiteVisitResults jobId={this.props.jobId}/>;
        }

        if(this.state.pdfDocument){
            return (
                <div>
                    <PdfViewer file={"data:application/pdf;base64,"+this.state.pdfDocument.documentBase64} download={this.downLoadPdf} />
                    <a ref="download" href={this.state.pdfDocument.documentUri} style={{display:"none"}} rel="noopener noreferrer" target="_blank"> </a>
                </div>);
        }

        if (this.state.organisationDocumentPdf) {
            return (
                <div>
                    <PdfViewer file={"data:application/pdf;base64,"+this.state.organisationDocumentPdf.documentBase64} download={() => this.downloadOrganisationDocument() } />
                </div>);
        }

        return (
            <p className="text-center">
                Unsupported file format to view.&nbsp;
                <button className="btn" onClick={this.downloadDocument} title="Download Document"><Icon name="download"/></button>
            </p>);
    }

    getDocumentListItem(document, wasManuallyUploaded) {
        if (this.state.organisationDocumentPdf) {
            return <List.Item
                key={document.documentId}
                onClick={() =>
                    wasManuallyUploaded ? this.fetchUploadedDoc(document) : this.fetchPdf(document.documentId)
                }
                active={this.state.selectedDoc === document.documentId}>
                <List.Icon name="file pdf" />
                {document.name}
            </List.Item>
        } else {
            return <List.Item
                as='a'
                key={document.documentId}
                onClick={() =>
                    wasManuallyUploaded ? this.fetchUploadedDoc(document) : this.fetchPdf(document.documentId)
                }
                active={this.state.selectedDoc === document.documentId}>
                <List.Icon name={wasManuallyUploaded ? "file" : "file pdf"} />
                {document.name}
            </List.Item>
        }
    }

    getSiteVisitListItem() {
        return <List.Item
            as='a'
            key='SiteVist'
            onClick={() =>
                this.setState( {
                    pdfDocument: null,
                    document:null,
                    siteVisit: true,
                    selectedDoc : null
                })
            }
            active={this.state.siteVisit}>
            <List.Icon name="file" />
            Site visit results
        </List.Item>
    }

    getDocumentCount(){
        let count = 0;
        if(this.state.documentList){
            count =+ this.state.documentList.length;
        }

        if(this.props.isSiteVisit){
            count += 1;
        }

        if (this.state.organisationDocumentPdf){
            count = 1;
        }

        return count;
    }

    noDocuments(){
        return this.getDocumentCount() === 0;
    }

    render () {
        let oneDoc = this.getDocumentCount() === 1;
        let bodyWidth = oneDoc ? 16 : 13;
        let heading = this.props.showOrganisationDocument ? this.state.organisationDocumentPdfFileName : "Documents"
        return (
            <Modal open={this.props.show === true}>
                <Modal.Header>
                    <Grid stackable columns={2}>
                        <Grid.Column className="floated left">
                            <h2>{heading}</h2>
                        </Grid.Column>
                        <Grid.Column>
                            <button className="btn btn-right" onClick={(event) => this.handleClose(event)}><p>Close<Icon name="close"/></p></button>
                        </Grid.Column>
                    </Grid>
                </Modal.Header>
                <Modal.Content>
                    <Grid stackable columns={2}>
                        {oneDoc?
                            null:
                            <Grid.Column width={3} className="document-menu">
                                <List selection>
                                    {this.getDocumentListItems()}
                                </List>
                            </Grid.Column>
                        }
                        <Grid.Column width={bodyWidth}>
                            <div className="documentBody">
                                {this.state.firstLoad === false ?
                                    <div className="content">
                                        {this.getDocumentContent()}
                                    </div>
                                    :
                                    <Spinner />
                                }
                            </div>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </Modal>
        );
    }
}
DocumentList.defaultProps = {
    show: false,
    isSiteVisit: false,
    showOrganisationDocument: false
}
DocumentList.propTypes = {
    jobId: PropTypes.string,
    jobTypeId: PropTypes.string,
    show: PropTypes.bool,
    closeModal: PropTypes.func,
    isSiteVisit: PropTypes.bool,
    organisationDocumentId: PropTypes.string,
    showOrganisationDocument: PropTypes.bool
}